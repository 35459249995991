import { axiosInstance } from '../../helpers/api_helper';
import axios from 'axios';

interface ProductList {
  id: number;
  productID: string;
  productName: string;
  productDescription: string;
  productModel: string;
  productPartNumber: string;
  productCategories: string;
  productPrice: number;
  productManufacturers: string;
  productVendor: string;
  productImage: string;
  productStateType: number;
  productStatus: number;
  vendorID: string;
  vendorName: string;
  vendorPhone: string;
  vendorEmail: string;
  vendorAddress: string;
  country: number;
  city: string;
  vendorRCNo: string;
  logo: string;
  status: number;
  catID: string;
  catName: string;
  created: string;
  type: string;
  productCreated: string;
  productStateLabel: string; // New field for state label
}

const baseURL = axiosInstance.defaults.baseURL;

async function fetchProductList(): Promise<ProductList[]> {
  const apiUrlProducts = `/Products`;
  const apiUrlOrders = `/Orders`;

  try {
    // Fetch both products and orders
    const [productResponse, orderResponse] = await Promise.all([
      axiosInstance.get(apiUrlProducts),
      axiosInstance.get(apiUrlOrders),
    ]);

    // Extract product and order IDs
    const productIDs = productResponse.data.map((product: ProductList) => product.productID);
    const orderProductIDs = orderResponse.data.map((order: any) => order.productID);

    // Filter out products that are in orders
    const productList: ProductList[] = productResponse.data
      .filter((product: ProductList) => !orderProductIDs.includes(product.productID))
      .filter((product: ProductList) => product.productStatus !== 0)
      .map(async (item: ProductList) => {
        try {
          // Fetch the product image using the productID
          //const imageResponse = await axiosInstance.get(`/Products/ProductImages/${item.productID}`);
          const imageResponse = await axios.get(`https://aeropartx.hatini.com.ng/Products/ProductImages/${item.productID}`);

          // Assuming the imageResponse.data contains the image details
          const imageDetails = imageResponse.data[0];

          console.log('imageDetails:', imageDetails);

          // Handle 404 errors
          if (!imageDetails) {
            console.error(`Image not found for productID: ${item.productID}`);
            return {
              ...item,
              productImage: null,
              productStateType: 'Unknown', // Default state label for missing image
            };
          }

          //const imageUrl = `${baseURL}/${imageDetails.image}`;
          const imageUrl = `https://aeropartx.hatini.com.ng/${imageDetails.image}`;
          console.log('imageUrl:', imageUrl);

          // Map productStateType to state label
          const productStateType = item.productStateType === 1 ? 'New' : item.productStateType === 2 ? 'Refurbished' : 'Unknown';

          return {
            ...item,
            productImage: imageUrl,
            productStateType,
          };
        } catch (error:any) {
          console.error('Error fetching product image:', error.message);
          return {
            ...item,
            productImage: null, // Set a default value when image fetching fails
            productStateType: 'Unknown', // Default state label for image fetching error
          };
        }
      });

    console.log('Product List', productList);
    return Promise.all(productList);
  } catch (error: any) {
    console.error('Error:', error.message);
    // Handle errors here
    throw error;
  }
}

export { fetchProductList, type ProductList };
