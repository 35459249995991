import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Alert, Spinner } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import Dropzone from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getAboutCardOne, AboutCardOne } from 'Common/data/aboutUsCardOne';
import { getAboutCardTwo, AboutCardTwo } from 'Common/data/aboutUsCardTwo';
import { getAboutCardThree, AboutCardThree } from 'Common/data/aboutUsCardThree';
import { updateAboutUsCardOne } from 'Common/data/updateAboutUsCardOne';
import { updateAboutUsCardTwo } from 'Common/data/updateAboutUsCardTwo';
import { updateAboutUsCardThree } from 'Common/data/updateAboutUsCardThree';

const Pillars = () => {
  
  const [aboutCardOne, setAboutCardOne] = useState<AboutCardOne | undefined>(undefined);
  const [aboutCardTwo, setAboutCardTwo] = useState<AboutCardTwo | undefined>(undefined);
  const [aboutCardThree, setAboutCardThree] = useState<AboutCardThree | undefined>(undefined);

    const [formData1, setFormData1] = useState({
      updatedBy: "Admin",
      pageStatus: "2",
      pageID: "f938ce6f-b82b-43f1-85df-411e6e2312e6",
      pageName: "",
      pageContent: "",
    });
  
    const [formData2, setFormData2] = useState({
      updatedBy: "Admin",
      pageStatus: "2",
      pageID: "cf12a32a-5bbf-4c79-9ff1-71b9eafe3caa",
      pageName: "",
      pageContent: "",
    });
  
    const [formData3, setFormData3] = useState({
      updatedBy: "Admin",
      pageStatus: "2",
      pageID: "b92871d4-0909-47c6-877f-8fa1e9af9558",
      pageName: "",
      pageContent: "",
    });
  
    const [successAlert, setSuccessAlert] = useState(false);
    const [processingPillar1, setProcessingPillar1] = useState(false);
    const [processingPillar2, setProcessingPillar2] = useState(false);
    const [processingPillar3, setProcessingPillar3] = useState(false);

    useEffect(() => {
      const fetchAboutData = async () => {
        try {
          const cardOneData = await getAboutCardOne();
          setAboutCardOne(cardOneData);
  
          const cardTwoData = await getAboutCardTwo();
          setAboutCardTwo(cardTwoData);
  
          const cardThreeData = await getAboutCardThree();
          setAboutCardThree(cardThreeData);
        } catch (error: any) {
          console.error('Error fetching About Us page:', error.message);
        }
      };
  
      fetchAboutData();
    }, []);
  
    const handleSubmitPillar1 = async (event: React.FormEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();
        setProcessingPillar1(true);
  
        const formData = { ...formData1 };
        formData.pageName = getElementValue('pillar1-title-input');
        formData.pageContent = getElementValue('pillar1-description-input');
  
        console.log('Updated Data for Pillar 1:', formData);
  
        await updateAboutUsCardOne(formData);
  
        console.log('Store details for Pillar 1 updated successfully!');
        setSuccessAlert(true);
  
        setTimeout(() => {
          setSuccessAlert(false);
        }, 3000);
      } catch (error: any) {
        console.error('Error updating store details for Pillar 1:', error.message);
      } finally {
        setProcessingPillar1(false);
      }
    };
  
    const handleSubmitPillar2 = async (event: React.FormEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();
        setProcessingPillar2(true);
  
        const formData = { ...formData2 };
        formData.pageName = getElementValue('pillar2-title-input');
        formData.pageContent = getElementValue('pillar2-description-input');
  
        console.log('Updated Data for Pillar 2:', formData);
  
        await updateAboutUsCardTwo(formData);
  
        console.log('Store details for Pillar 2 updated successfully!');
        setSuccessAlert(true);
  
        setTimeout(() => {
          setSuccessAlert(false);
        }, 3000);
      } catch (error: any) {
        console.error('Error updating store details for Pillar 2:', error.message);
      } finally {
        setProcessingPillar2(false);
      }
    };
  
    const handleSubmitPillar3 = async (event: React.FormEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();
        setProcessingPillar3(true);
  
        const formData = { ...formData3 };
        formData.pageName = getElementValue('pillar3-title-input');
        formData.pageContent = getElementValue('pillar3-description-input');
  
        console.log('Updated Data for Pillar 3:', formData);
  
        await updateAboutUsCardThree(formData);
  
        console.log('Store details for Pillar 3 updated successfully!');
        setSuccessAlert(true);
  
        setTimeout(() => {
          setSuccessAlert(false);
        }, 3000);
      } catch (error: any) {
        console.error('Error updating store details for Pillar 3:', error.message);
      } finally {
        setProcessingPillar3(false);
      }
    };
  
    function getElementValue(elementId: string): string {
      const element = document.getElementById(elementId);
  
      if (element && 'value' in element) {
        return element.value as string;
      }
  
      return '';
    }

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm">
                <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                  <i className="bi bi-box-seam"></i>
                </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="card-title mb-1">Pillars</h5>
              <p className="text-muted mb-0">Fill all information below.</p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {/* Pillar 1 Title */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar1-title-input">Pillar 1 Title</Form.Label>
              <Form.Control
                type="text"
                id="pillar1-title-input"
                defaultValue={aboutCardOne?.pageName || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
          </Card.Body>

          {/* Pillar 1 Description */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar1-description-input">Pillar 1 Description</Form.Label>
              <Form.Control
                as="textarea"
                id="pillar1-description-input"
                defaultValue={aboutCardOne?.pageContent || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
            <Button type="submit" variant="primary" onClick={handleSubmitPillar1}>
              {processingPillar1 ? <Spinner animation="border" size="sm" className="me-2" /> : null}
              Save Changes for Pillar 1
            </Button>
            {successAlert && (
              <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                <p>Store details updated successfully!</p>
              </Alert>
            )}
          </Card.Body>

          {/* Pillar 2 Title */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar2-title-input">Pillar 2 Title</Form.Label>
              <Form.Control
                type="text"
                id="pillar2-title-input"
                defaultValue={aboutCardTwo?.pageName || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
          </Card.Body>

          {/* Pillar 2 Description */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar2-description-input">Pillar 2 Description</Form.Label>
              <Form.Control
                as="textarea"
                id="pillar2-description-input"
                defaultValue={aboutCardTwo?.pageContent || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
            <Button type="submit" variant="primary" onClick={handleSubmitPillar2}>
              {processingPillar2 ? <Spinner animation="border" size="sm" className="me-2" /> : null}
              Save Changes for Pillar 2
            </Button>
            {successAlert && (
              <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                <p>Store details updated successfully!</p>
              </Alert>
            )}
          </Card.Body>

          {/* Pillar 3 Title */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar3-title-input">Pillar 3 Title</Form.Label>
              <Form.Control
                type="text"
                id="pillar3-title-input"
                defaultValue={aboutCardThree?.pageName || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
          </Card.Body>

          {/* Pillar 3 Description */}
          <Card.Body>
            <div className="mb-3">
              <Form.Label htmlFor="pillar2-description-input">Pillar 3 Description</Form.Label>
              <Form.Control
                as="textarea"
                id="pillar3-description-input"
                defaultValue={aboutCardThree?.pageContent || ''}
                placeholder="Enter information"
                required
              />
              <div className="invalid-feedback">Please enter information.</div>
            </div>
            <Button type="submit" variant="primary" onClick={handleSubmitPillar3}>
              {processingPillar3 ? <Spinner animation="border" size="sm" className="me-2" /> : null}
              Save Changes for Pillar 3
            </Button>
            {successAlert && (
              <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                <p>Store details updated successfully!</p>
              </Alert>
            )}
          </Card.Body>

        </Card.Body>
      </Card>
    </>
  );
};

export default Pillars;
