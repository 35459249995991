import React, {useState} from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import TopBarInfo from './TopBarInfo';
import Slider from './Slider';
import BannerOneInfoCard from './BannerOneInfoCard';
import BannerTwoInfoCard from './BannerTwoInfoCard';
import BannerThreeInfoCard from './BannerThreeInfoCard';
import LatestArrival from './LatestArrival';
import FollowUs from './FollowUs';
import Subscribe from './Subscribe';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DashboardManagement = () => {

    document.title = "Content Management | AeroPartX";

    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    /* Formats the size */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Dashboard Management" pageTitle="Content Management" />
                    <form id="dashboardmanagement-form" autoComplete="off" className="needs-validation" noValidate>
                        <Row>
                            <Col xl={9} lg={8}>
                                <TopBarInfo />
                                <Slider />
                                <BannerOneInfoCard />
                                <BannerTwoInfoCard />
                                <BannerThreeInfoCard />
                                <Subscribe />
                            </Col>

                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default DashboardManagement;