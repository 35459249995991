import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Seller, fetchAllSellers } from "../../../Common/data/allSellers";
import { fetchOrdersCount } from 'Common/data/nooforders';
import { useNavigate } from 'react-router-dom';

interface ListViewTableProps {
    searchInput: string;
    onCountsUpdate: (total: number, active: number, inactive: number) => void;
    statusFilter: string;
    dateFilter: string;
    onEditSeller: (vendorID: string) => void;
}

const ListViewTable: React.FC<ListViewTableProps> = ({ searchInput, onCountsUpdate, statusFilter, dateFilter, onEditSeller }) => {
    const navigate = useNavigate();

    const [sellers, setSellers] = useState<Seller[]>([]);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState<Record<string, number>>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sellersList = await fetchAllSellers();
                setSellers(sellersList);
                setLoading(false);
            } catch (error:any) {
                console.error('Error fetching sellers list:', error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchCountForAllSellers = async () => {
            try {
                const countPromises = sellers.map(async (seller) => {
                    try {
                        const count = await fetchOrdersCount(seller.vendorID);
                        console.log(`Count for seller ${seller.vendorID}:`, count);
                        return { vendorID: seller.vendorID, count };
                    } catch (error:any) {
                        console.error(`Error fetching orders count for seller ${seller.vendorID}:`, error.message);
                        return { vendorID: seller.vendorID, count: 0 };
                    }
                });

                const counts = await Promise.all(countPromises);

                const countMap: Record<string, number> = {};
                counts.forEach((item) => {
                    countMap[item.vendorID] = item.count;
                });

                console.log('Number of orders for each seller:', countMap);

                setOrdersCount((prevOrdersCount) => ({ ...prevOrdersCount, ...countMap }));

                const totalSellers = sellers.length;
                const activeSellers = sellers.filter((seller) => Number(seller.status) === 1).length;
                const inactiveSellers = sellers.filter((seller) => Number(seller.status) === 0).length;

                console.log('Total Sellers:', totalSellers);
                console.log('Active Sellers:', activeSellers);
                console.log('Inactive Sellers:', inactiveSellers);

                onCountsUpdate(totalSellers, activeSellers, inactiveSellers);
            } catch (error) {
                console.error('Error in fetchCountForAllSellers:', error);
            }
        };

        if (sellers.length > 0) {
            fetchCountForAllSellers();
        }
    }, [sellers, onCountsUpdate, statusFilter, dateFilter]);

    const filteredSellers = useMemo(() => {
        return sellers.filter(seller =>
            seller.vendorName.toLowerCase().includes(searchInput.toLowerCase()) ||
            seller.vendorEmail.toLowerCase().includes(searchInput.toLowerCase()) ||
            seller.vendorPhone.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [sellers, searchInput]);

    const handleEditSeller = (seller: any) => {
        onEditSeller(seller.vendorID);
    };

    const columns = useMemo(
        () => [
            // {
            //     Header: (<div className="form-check"> <input className="form-check-input" type="checkbox" id="checkAll" value="option" /> </div>),
            //     Cell: (cellProps: any) => {
            //         return (<div className="form-check"> <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" /> </div>);
            //     },
            //     id: '#',
            // },
            {
                Header: "Seller Name",
                accessor: "vendorName",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Email",
                accessor: "vendorEmail",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Phone",
                accessor: "vendorPhone",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Account Status",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    const accountStatus = cellProps.status;
                    const statusText = accountStatus === 1 ? "Active" : "Inactive";

                    return (
                        <span className={`badge ${accountStatus === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'} text-uppercase`}>
                            {statusText}
                        </span>
                    );
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {

                    return (
                        <div className="d-flex gap-2">
                            <div>
                                <Link 
                                    to={`/seller-overview/${cellProps.vendorID}`}
                                    className="btn btn-sm btn-soft-secondary"
                                >
                                    View
                                </Link>
                            </div>
                            <div>
                                <Button
                                    variant='soft-info'
                                    size="sm"
                                    className="edit-item-btn"
                                    onClick={() => handleEditSeller(cellProps)}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [ordersCount, statusFilter, dateFilter]
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={(columns || [])}
                data={(filteredSellers || [])}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
        </React.Fragment>
    );
};

export default ListViewTable;
