import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, Form, Row, Spinner } from 'react-bootstrap';
import { updateAboutSectionTwo } from '../../../Common/data/updateAboutSectionTwo';
import { getAboutSectionTwo, AboutSectionTwo } from 'Common/data/aboutSectionTwo';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AboutUsStepThree = () => {
    const [successAlert, setSuccessAlert] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [aboutData, setAboutData] = useState<AboutSectionTwo | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchStoreDetails();
    }, []);

    const fetchStoreDetails = async () => {
        try {
            const data = await getAboutSectionTwo();
            setAboutData(data);
            setLoading(false);
        } catch (error: any) {
            console.error('Error fetching store details:', error.message);
        }
    };

    const validationSchema = Yup.object().shape({
        pageName: Yup.string().required('Title is required'),
        pageContent: Yup.string().required('Description is required')
    });

    const handleSubmit = async (values: any) => {
        setProcessing(true);

        try {
            await updateAboutSectionTwo(values);

            setSuccessAlert(true);

            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        } catch (error: any) {
            console.error('Error updating about section two details:', error.message);
        } finally {
            setProcessing(false);
        }
    };

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <Card>
            <Card.Header>
                <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                            <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                <i className="bi bi-box-seam"></i>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Section 2 Information</h5>
                        <p className="text-muted mb-0">Fill all information below.</p>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Formik
                    initialValues={{
                        updatedBy: "Admin",
                        pageStatus: "2",
                        pageID: "e859ad1d-2364-4415-9841-009ed73c6fd9",
                        pageName: aboutData?.pageName || '',
                        pageContent: aboutData?.pageContent || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ isSubmitting, errors, touched, values }) => (
                        <Form>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="pageName">Title</Form.Label>
                                        <Field
                                            type="text"
                                            id="pageName"
                                            name="pageName"
                                            className={`form-control ${errors.pageName && touched.pageName ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="pageName" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="pageContent">Description</Form.Label>
                                        <Field
                                            as="textarea"
                                            id="pageContent"
                                            name="pageContent"
                                            className={`form-control ${errors.pageContent && touched.pageContent ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="pageContent" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>

                            {successAlert && (
                                <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                                    <p>About section two details updated successfully!</p>
                                </Alert>
                            )}

                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmitting || Object.keys(errors).length > 0}
                                onClick={() => handleSubmit(values)}
                            >
                                {processing ? (
                                    <Spinner animation="border" size="sm" className="me-2" />
                                ) : null}
                                Save Changes
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default AboutUsStepThree;
