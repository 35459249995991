import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, Form, Row, Spinner } from 'react-bootstrap';
import { updateSliderContent } from '../../../Common/data/updateslider';
import { getSliderDetails, SliderData } from '../../../Common/data/sliderdetails';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Slider = () => {
  const [sliderData, setSliderData] = useState<SliderData[] | undefined>(undefined);
  const [successAlert, setSuccessAlert] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSliderDetails();
  }, []);

  const fetchSliderDetails = async () => {
    try {
      const data = await getSliderDetails();
      setSliderData(data);
      setLoading(false);
    } catch (error: any) {
      console.error('Error fetching slider details:', error.message);
    }
  };

  const initialValues = {
    sliderTitle: sliderData?.[0]?.sliderTitle || '',
    sliderSubTitle: sliderData?.[0]?.sliderSubTitle || '',
    userID: "5f022f00-476c-4e4d-aa6b-9274953c71be",
    sliderContent: "Another awesome product",
    sliderBtnColor: "btn-success",
    sliderID: "fb9e267d-4ebe-4a12-a6e5-f103ce8ff061"
  };

  const validationSchema = Yup.object().shape({
    sliderTitle: Yup.string().required('Title is required'),
    sliderSubTitle: Yup.string().required('Subtitle is required')
  });

  const handleSubmit = async (values: any) => {
    setProcessing(true);

    try {
      await updateSliderContent(values);

      setSuccessAlert(true);
      setTimeout(() => {
        setSuccessAlert(false);
      }, 3000);
    } catch (error: any) {
      console.error('Error updating slider details:', error.message);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Card>
      <Card.Header>
        <div className="d-flex">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm">
              <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                <i className="bi bi-box-seam"></i>
              </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <h5 className="card-title mb-1">Slider Information</h5>
            <p className="text-muted mb-0">Fill all information below.</p>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ isSubmitting, errors, touched, values }) => (
            <Form>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="sliderTitle">Title</Form.Label>
                    <Field
                      type="text"
                      id="sliderTitle"
                      name="sliderTitle"
                      className={`form-control ${errors.sliderTitle && touched.sliderTitle ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage name="sliderTitle" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="sliderSubTitle">Subtitle</Form.Label>
                    <Field
                      type="text"
                      id="sliderSubTitle"
                      name="sliderSubTitle"
                      className={`form-control ${errors.sliderSubTitle && touched.sliderSubTitle ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage name="sliderSubTitle" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              {successAlert && (
                <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                  <p>Slider details updated successfully!</p>
                </Alert>
              )}
              <Button
                type="button"
                variant="primary"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={() => handleSubmit(values)}
              >
                {processing ? (
                  <Spinner animation="border" size="sm" className="me-2" />
                ) : null}
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default Slider;
