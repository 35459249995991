import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { fetchVendorProfileDetails, Profile } from '../../../Common/data/vendorprofile';
import { fetchVendorProductList, ProductList } from '../../../Common/data/vendorproducts';

import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

const SellersOverview = () => {
    document.title = "Overview - Sellers | AeroPartX";

    const { vendorId } = useParams();
    const [vendorProfile, setVendorProfile] = useState<Profile | null>(null);
    const [productList, setProductList] = useState<ProductList[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (vendorId) {
                    const profile = await fetchVendorProfileDetails(vendorId);
                    setVendorProfile(profile);

                    const products = await fetchVendorProductList(vendorId);
                    setProductList(products);
                } else {
                    console.error('Vendor ID is undefined');
                }
            } catch (error: any) {
                console.error('Error fetching data:', error.message);
                // Handle errors here
            }
        };

        fetchData();
    }, [vendorId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card className="border-0 border-bottom border-bottom-dashed shadow-none mx-n4 mt-n4">
                                <Card.Body className="profile-basic position-relative">
                                    <div className="bg-primary bg-opacity-75 position-absolute start-0 end-0 top-0 bottom-0"></div>
                                </Card.Body>
                                <Card.Body className="position-relative mt-n3">
                                    <div className="mt-n5">
                                        <div className="avatar-lg">
                                            <div className="avatar-title bg-white shadow rounded">
                                                <img src={dummyImg} alt="" className="avatar-sm" />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                                <Card.Body className="pt-0">
                                    <Row className="justify-content-between gy-4">
                                        <Col xl={5} md={7}>
                                            <h5 className="mb-2">{vendorProfile?.vendorName} <i className="ph-circle-wavy-check-fill text-primary align-top fs-15"></i></h5>
                                            <p><b>Since: {vendorProfile?.Created ? new Date(vendorProfile.Created).getFullYear() : 'Not available'}</b></p>
                                            <div className="mb-2 text-muted">
                                                <i className="bi bi-geo-alt align-middle me-1"></i> {vendorProfile?.city}
                                            </div>
                                            <div className="mb-2 text-muted">
                                                <i className="bi bi-envelope align-middle me-1"></i> {vendorProfile?.vendorEmail}
                                            </div>
                                        </Col>
                                        <Col xl={3} md={5}>
                                            <div className="d-flex flex-column h-100">
                                                <div className="text-end mt-auto">
                                                    <Button className="btn custom-primary-btn">Contact</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="g-3 align-items-center mb-4">
                        <Col lg={3} className="me-auto">
                            <h5 className="fs-17 mb-0">Products by seller</h5>
                        </Col>
                        <Col lg={2}>
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control search"
                                    placeholder="Search for products..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row-cols-xxl-5 row-cols-md-2 row-cols-1">
                        {productList
                            .filter(item => item.productName.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map((item: ProductList, key: number) => (
                                <Col key={key}>
                                    <Card className="element-item">
                                        <div className="bg-light overflow-hidden">
                                            <div className="gallery-product">
                                                <img src={item.productImage} alt="" style={{ maxWidth: "100%", minHeight: "250px", objectFit: "cover" }} className="mx-auto d-block" />
                                            </div>
                                        </div>
                                        <Card.Body>
                                            <div>
                                                <Link to={`/product-overview/${item.productID}`}>
                                                    <h6 className="fs-18 lh-base text-truncate mb-0 text-capitalize">{item.productName}</h6>
                                                </Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                    </Row>

                    <Row className="g-0 text-center text-sm-start align-items-center mb-3">
                        <Col sm={6}>
                            <div>
                                <p className="mb-sm-0">Showing <b>1</b> to <b>{productList.length}</b> of <b>{productList.length}</b> entries</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end flex-wrap gap-1 gap-sm-0 mb-sm-0">
                                <li className="page-item disabled"> <Link to="#" className="page-link">Previous</Link> </li>
                                {Array.from({ length: Math.ceil(productList.length / 5) }, (_, index) => (
                                    <li key={index} className={`page-item ${index === 0 ? 'active' : ''}`}> <Link to="#" className="page-link">{index + 1}</Link> </li>
                                ))}
                                <li className="page-item"> <Link to="#" className="page-link">Next</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SellersOverview;
