import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { changePassword } from '../../../Common/data/changepassword';
import { adminLogin } from '../../../Common/data/adminlogin'; // Import the adminLogin function

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const ChangePassword = () => {
    const [passwordShow, setPasswordShow] = useState<any>(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState<any>(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState<string>(''); // State to track old password error message

    const authUser = localStorage.getItem('authUser');
    const { data: { profileID, email } } = authUser ? JSON.parse(authUser) : { data: { profileID: '', email: '' } };

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            old_password: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required("Please Enter Your Old Password"),
            password: Yup.string().required("Please Enter Your Password").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
            confirm_password: Yup.string().required("Please re-enter password").oneOf([Yup.ref('password')], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                // Extract email and old_password from the values object
                const { old_password } = values;
                const authUser = localStorage.getItem('authUser');
                const { data: { email } } = authUser ? JSON.parse(authUser) : { data: { email: '' } };
        
                console.log('Payload sent to admin login:', { email, old_password });
        
                // Attempt to log in with old password
                const adminLoginResponse = await adminLogin(email, old_password);
        
                // If login successful, proceed to change password
                if (adminLoginResponse) {
                    const formData = {
                        profileID,
                        old_password,
                        password: values.password,
                    };
        
                    // Call the API to change the password
                    const response = await changePassword(formData);
                    console.log('Password changed successfully:', response);
                    setShowSuccessAlert(true);
                } else {
                    // If login unsuccessful, set error message for old password field
                    setOldPasswordError('Old password is incorrect');
                }
            } catch (error:any) {
                console.error('Error changing password:', error.message);
            } finally {
                setIsLoading(false);
            }
        }        
    });

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Change Password</h5>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            {showSuccessAlert && (
                                <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                                    Password changed successfully!
                                </Alert>
                            )}
                            <Row className="g-3">
                                <Col lg={4}>
                                    <Form>
                                        <Form.Label htmlFor="old-password-input">Old Password</Form.Label>
                                        <div className="position-relative auth-pass-inputgroup">
                                            <Form.Control
                                                type={passwordShow ? "text" : "password"} className="pe-5 password-input" placeholder="Enter old password" id="old-password-input"
                                                name="old_password"
                                                autoComplete='off'
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.old_password || ""}
                                            />
                                            <Button variant='link' className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="old-password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                            {oldPasswordError && <div className="text-danger">{oldPasswordError}</div>} {/* Display old password error message */}
                                        </div>
                                    </Form>
                                </Col>

                                <Col lg={4}>
                                    <Form>
                                        <Form.Label htmlFor="password-input">Password</Form.Label>
                                        <div className="position-relative auth-pass-inputgroup">
                                            <Form.Control
                                                type={passwordShow ? "text" : "password"} className="pe-5 password-input" placeholder="Enter password" id="password-input"
                                                name="password"
                                                autoComplete='off'
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.password || ""}
                                            />
                                            <Button variant='link' className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                            {validation.touched.password && validation.errors.password && <div className="text-danger">{validation.errors.password}</div>} {/* Display password error message */}
                                        </div>
                                    </Form>
                                </Col>

                                <Col lg={4}>
                                    <Form>
                                        <Form.Label htmlFor="confirm-password-input">Confirm Password</Form.Label>
                                        <div className="position-relative auth-pass-inputgroup">
                                            <Form.Control type={confirmPasswordShow ? "text" : "password"} className="pe-5 password-input" placeholder="Confirm password" id="confirm-password-input"
                                                name="confirm_password"
                                                autoComplete='off'
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.confirm_password || ""}
                                            />
                                            <Button variant='link' className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                            {validation.touched.confirm_password && validation.errors.confirm_password && <div className="text-danger">{validation.errors.confirm_password}</div>} {/* Display confirm password error message */}
                                        </div>
                                    </Form>
                                </Col>

                                <Col lg={12}>
                                    <div className="text-end">
                                        <Button 
                                            variant='success' 
                                            type="submit" 
                                            className="custom-secondary-btn"
                                            onClick={validation.handleSubmit}
                                        >
                                            {isLoading ? <Spinner animation="border" size="sm" /> : "Change Password"}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ChangePassword;
