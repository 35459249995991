import React, { useState, useRef } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import ListViewTable from './listViewTable';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/material_green.css';

const OrdersListView = () => {
    document.title = "List - Orders | AeroPartX";

    const [searchInput, setSearchInput] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const flatpickrRef = useRef<Flatpickr>(null);

    const clearDateFilter = () => {
        setSelectedDate(null);
        // Clear the value of the Flatpickr input
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
        }
    };

    const currentDate = new Date(); // Get current date
    const options = {
        dateFormat: "d M, Y",
        maxDate: currentDate, // Set maximum date to current date
        onChange: (selectedDates: Date[]) => setSelectedDate(selectedDates[0] || null),
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="List View" pageTitle="Orders" />

                    <Row id="orderList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col xxl={4} sm={6}>
                                            <div className="search-box">
                                                <input 
                                                    type="text" 
                                                    className="form-control search" 
                                                    placeholder="Search for order..." 
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>

                                        <Col xxl={2} sm={6}>
                                            <div>
                                                <Flatpickr
                                                    ref={flatpickrRef}
                                                    className="form-control flatpickr-input"
                                                    placeholder='Select Date'
                                                    value={selectedDate || undefined}
                                                    options={options}
                                                />
                                            </div>
                                        </Col>

                                        <Col xxl={2} sm={12}>
                                            <div>
                                                <Button 
                                                    variant='primary' 
                                                    onClick={clearDateFilter} 
                                                    className="w-100 custom-primary-btn"
                                                >
                                                    Clear Date Filter
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            
                            {/* ListViewTable */}
                            <ListViewTable searchInput={searchInput} selectedDate={selectedDate} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default OrdersListView;
