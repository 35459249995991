import BreadCrumb from 'Common/BreadCrumb';
import React from 'react'
import { Container, Row } from 'react-bootstrap';

import avatar10 from "assets/images/users/avatar-10.jpg";
import avatar8 from "assets/images/users/avatar-8.jpg";
import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar4 from "assets/images/users/avatar-4.jpg";
import avatar3 from "assets/images/users/avatar-3.jpg";
import avatar5 from "assets/images/users/avatar-5.jpg";
import avatar7 from "assets/images/users/avatar-7.jpg";
import avatar9 from "assets/images/users/avatar-9.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import avatar6 from "assets/images/users/avatar-6.jpg";
import ReviewsRating from './Reviews&Rating';

const ReviewRating = () => {

    document.title = "Reviews & Ratings | AeroPartX";

    const reviews = [
        {
            id : 1,
            img : avatar10,
            name : "Scott Barber",
            subTitle : "Absolutely thrilled with my recent purchase from Aeropartx! The aviation product I bought is top-notch. The quality and craftsmanship are outstanding. It's evident that the seller takes great pride in their offerings."
        },
        {
            id : 2,
            img : avatar8,
            name : "Daniel Owen",
            subTitle : "Exceptional experience shopping on Aeropartx! The aviation product I received exceeded my expectations. The seller's attention to detail and commitment to quality are truly commendable. Looking forward to exploring more products from this seller."
        },
        {
            id : 3,
            img : avatar2,
            name : "Patrick Kelly",
            subTitle : "I recently bought an aviation part from this seller on Aeropartx, and I'm thoroughly impressed. The product arrived on time and in perfect condition. The seller's professionalism and expertise are evident. Highly recommended!"
        },
        {
            id : 4,
            img : avatar1,
            name : "Victoria Le",
            subTitle : "What a fantastic find on Aeropartx! The aviation product I purchased is exactly what I needed."
        },
        {
            id : 5,
            img : avatar4,
            name : "Daniel Mejia",
            subTitle : "I've been searching for a specific aviation component for a while, and I finally found it on Aeropartx. The seller provided detailed information about the product, and it arrived securely packaged."
        },
        {
            id : 6,
            img : avatar3,
            name : "Nicole Davis",
            subTitle : "I'm a satisfied customer of this seller on AeroPartX. The aviation part I bought is of superior quality, and the seller was extremely helpful throughout the buying process. "
        },
        {
            id : 7,
            img : avatar5,
            name : "Jessica Thomas",
            subTitle : "Incredibly pleased with my purchase from this seller"
        },
        {
            id : 8,
            img : avatar6,
            name : "Elizabeth Padilla",
            subTitle : "Impressed with the seller's professionalism"
        },
        {
            id : 9,
            img : avatar7,
            name : "Alexis Davidson",
            subTitle : "The aviation product I ordered arrived promptly and was precisely what I needed."
        },
        {
            id : 10,
            img : avatar9,
            name : "Stephen Bird",
            subTitle : "he seller's attention to detail and commitment to customer satisfaction are evident"
        },
        {
            id : 11,
            img : avatar2,
            name : "Kristina Hooper",
            subTitle : "I feel confident in my purchase and would recommend this seller to anyone in the aviation community."
        },
        {
            id : 12,
            img : avatar6,
            name : "Jacques Leon",
            subTitle : "A trustworthy and reliable choice for aviation needs."
        },
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reviews & Ratings" pageTitle="More" />
                    <Row>
                        <ReviewsRating reviews={reviews} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ReviewRating
