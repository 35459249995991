import React, { useState, useEffect, useMemo } from 'react';
import { fetchProductList, ProductList } from 'Common/data/productlist';
import TableContainer from 'Common/TableContainer';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import AddOrderModal from '../Modal/index';
import { insertOrder } from 'Common/data/insertorder';
import { Link } from 'react-router-dom';

interface ProductTableProps {
  selectedCategories: string[];
}

const ProductTable: React.FC<ProductTableProps> = ({ selectedCategories }) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [productList, setProductList] = useState<ProductList[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null);
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);

  const handleAddOrderSubmit = async (customerId: string) => {
    try {
      const orderData = await insertOrder({
        productID: selectedProductId,
        vendorID: selectedVendorId,
        customerID: customerId,
        qty: '0',
        price: '0',
        comment: 'Not needed',
      });

      console.log('Order inserted successfully:', orderData);

      setShowAddOrderModal(false);

      // Navigate to the orders list view
      // navigate('/orders-list-view');
    } catch (error: any) {
      console.error('Error inserting order:', error.message);
    }
  };

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };

  useEffect(() => {
    const fetchProductListData = async () => {
      try {
        const productDataPromises = await fetchProductList();
        const productData = await Promise.all(productDataPromises);

        const filteredData = selectedCategories.length > 0
          ? productData.filter(product => {
            return selectedCategories.some(selectedCatName => selectedCatName === product.catName);
          })
          : productData;

        const sortedProductList = filteredData.sort((a, b) => {
          return moment(a.productCreated).unix() - moment(b.productCreated).unix();
        });

        setProductList(sortedProductList);
      } catch (error: any) {
        console.error('Error fetching product list:', (error as Error).message);
      }
    };

    fetchProductListData();
  }, [selectedCategories]);

  const filteredProductList = useMemo(() => {
    return productList.filter(product =>
      product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [productList, searchQuery]);

  const handleAddOrder = (productId: string, vendorId: string) => {
    setSelectedProductId(productId);
    setSelectedVendorId(vendorId);
    setShowAddOrderModal(true);
  };

  const columns = useMemo(() => [
    {
      Header: "Part name",
      Cell: (product: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-2">
              <div className="avatar-sm bg-light rounded p-1">
                <img
                  src={product.row.original.productImage}
                  alt=""
                  className="img-fluid d-block avatar-xs"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/product-overview/${product.row.original.productID}`}
                  className="d-block text-reset"
                >
                  {" "}
                  {product.row.original.productName}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                Category :{" "}
                <span className="fw-medium">
                  {" "}
                  {product.row.original.catName}
                </span>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "Part Number",
      accessor: "productPartNumber",
      Filter: false,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      Filter: false,
    },
    {
      Header: "Condition",
      accessor: "productStateType",
      Filter: false,
    },
    {
      Header: "Created",
      Filter: false,
      accessor: (cellProps: any) => {
        return (<span>{handleValidDate(cellProps.productCreated)}<small className="text-muted ms-1">{handleValidTime(cellProps.productCreated)}</small></span>)
      }
    },
    {
      Header: "Action",
      Cell: (cellProps: any) => {
        return (
          <React.Fragment>
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleAddOrder(cellProps.row.original.productID, cellProps.row.original.vendorID)}>Add Order</Dropdown.Item>
                {
                  /* <Dropdown.Item>Delete</Dropdown.Item> */
                }
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        );
      },
    },
  ],
    []
  );

  return (
    <React.Fragment>
      <Row className="g-4 mb-4">
        <Col className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <Form.Control
                type="text"
                autoComplete="off"
                id="searchProductList"
                placeholder="Search Parts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <TableContainer
          columns={columns}
          data={(filteredProductList || [])}
          // isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={10}
          // divClassName="table-responsive mb-1"
          tableClass="gridjs-table"
          theadClass="gridjs-th"
          isProductsFilter={true}
          SearchPlaceholder='Search Products...'
        />
      </div>
      <AddOrderModal
        showModal={showAddOrderModal}
        onHide={() => setShowAddOrderModal(false)}
        handleAddOrderSubmit={handleAddOrderSubmit}
      />
    </React.Fragment>
  );
};

export default ProductTable;
