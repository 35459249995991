import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface Category {
    id: number;
    name: string;
    createdAt: string;
    status: string;
}

async function updateCategory(catID: string, name: string, status: number): Promise<Category | undefined> {
    const apiUrl = '/Categories/UpdateCategory';

    try {
        const response: AxiosResponse<Category> = await axiosInstance.patch(apiUrl, { catID, name, status });

        // Log the entire response data
        console.log('Updated Category Response Data:', response);

        // Return the updated category information
        return response.data;
    } catch (error: any) {
        console.error('Error updating category:', error.message);
        // Handle errors here
        throw error;
    }
}

export { updateCategory };
