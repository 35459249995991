import { axiosInstance } from '../../helpers/api_helper';

interface UpdateSliderResponse {
  responseCode: string;
  responseMessage: string;
}

async function updateSliderContent(sliderData: any): Promise<UpdateSliderResponse> {
  const apiUrl = '/FrontPage/UpdateSlider';

  try {
    console.log('Slider Data in updateSliderContent:', sliderData);

    const response = await axiosInstance.patch(apiUrl, sliderData);

    console.log('API Response from updateSliderContent:', response); // Log the response

    const responseData: UpdateSliderResponse = {
      responseCode: response.data.ResponseCode,
      responseMessage: response.data.ResponseMessage
    };

    if (responseData.responseCode === "00") {
      // Handle success, if needed
      return responseData;
    } else {
      // Handle error
      throw new Error(responseData.responseMessage || 'Error updating slider content');
    }
  } catch (error: any) {
    // Handle network or other errors
    throw new Error(`Error: ${error.message}`);
  }
}

export { updateSliderContent };
