import { axiosInstance } from '../../helpers/api_helper';

interface FormData {
  // Define the structure of your formData
  updatedBy: string;
  pageStatus: string;
  pageID: string;
  pageName: string;
  pageContent: string;
  // Add more fields if needed
}

interface ApiResponse {
  // Define the structure of your API response
  ResponseCode: string;
  // Add more fields if needed
}

const updateAboutSectionTwo = async (formData: FormData): Promise<ApiResponse> => {
  try {
    console.log('FormData in updateStoreDetails:', formData);

    const response = await axiosInstance.patch(`/FrontPage/UpdatePage`, formData);

    console.log('API Response from updateStoreDetails:', response); // Log the response

    if (response.data.message === "Successfull") {
      // Handle success, if needed
      return response.data as ApiResponse;
    } else {
      // Handle error
      throw new Error('Error updating store details');
    }
  } catch (error: any) {
    // Handle network or other errors
    throw new Error(`Error: ${error.message}`);
  }
};

export { updateAboutSectionTwo };
