import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { fetchRecentOrdersList, RecentOrders } from "../../Common/data/orderslist";

import { IncomeStatisticsCharts, PurchaseCharts, TopSellingProductCharts, OrdersbyCountriesCharts } from './StatisticsCharts';

interface FilteredData {
    city: string;
    id: number;
    orderDate: string;
    catName: string;
    // Add other properties needed for filtering
  }

  const Statistics: React.FC = () => {
    // Set the page title
    document.title = "Statistics | AeroPartX";
  
    // Define state variables
    const [topSellingProductData, setTopSellingProductData] = useState<RecentOrders[]>([]);
    const [seriesData, setSeriesData] = useState<{ name: string; data: { month: string; year: number; count: number }[] }[]>([]);
    const [ordersByCity, setOrdersByCity] = useState<{ [city: string]: number }>({});
  
    // Fetch data function
    const fetchData = async () => {
      try {
        // Fetch recent orders data
        const data = await fetchRecentOrdersList();
        setTopSellingProductData(data);
  
        // Structure the data for filtering
        const filteredData: FilteredData[] = data.map(order => ({
          id: order.id,
          orderDate: order.orderDate,
          catName: order.catName,
          country: order.country,
          city: order.city,
          // Add other properties needed for filtering
        }));
  
        // Extract categories and count occurrences
        const categoryCount: { [category: string]: number } = {};
  
        filteredData.forEach(order => {
          const categoryName = order.catName;
  
          // Initialize the category count if not already initialized
          if (!categoryCount[categoryName]) {
            categoryCount[categoryName] = 0;
          }
  
          // Update the category count
          categoryCount[categoryName]++;
        });
  
        // Sort categories based on the total count
        const sortedCategories = Object.keys(categoryCount).sort((a, b) => categoryCount[b] - categoryCount[a]);
  
        // Take the top 4 categories
        const topCategories = sortedCategories.slice(0, 4);
  
        // Group the remaining categories into "Other"
        const otherCategories = sortedCategories.slice(4);
  
        // Create the series array with a modified TypeScript type
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
  
        const series: { name: string; data: { month: string; year: number; count: number }[] }[] = topCategories.map(category => {
          const categoryData = Array.from({ length: currentMonth }, (_, i) => {
            const month = i + 1;
            const filteredOrders = filteredData.filter(order => order.catName === category && new Date(order.orderDate).getMonth() + 1 === month && new Date(order.orderDate).getFullYear() === currentYear);
            return {
              month: new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' }),
              year: currentYear,
              count: filteredOrders.length > 0 ? filteredOrders.reduce((total, order) => total + 1, 0) : 0,
            };
          });
  
          return {
            name: category, // Use actual category names as the name property
            data: categoryData,
          };
        });
  
        // Add "Other" category to series if applicable
        if (otherCategories.length) {
          const otherData = Array.from({ length: currentMonth }, (_, i) => {
            const month = i + 1;
            const filteredOrders = filteredData.filter(order => otherCategories.includes(order.catName) && new Date(order.orderDate).getMonth() + 1 === month && new Date(order.orderDate).getFullYear() === currentYear);
            const monthName = new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' }); // Get short month name
            const monthCount = filteredOrders.length > 0 ? filteredOrders.reduce((total, order) => total + 1, 0) : 0;
  
            return { month: monthName, year: currentYear, count: monthCount };
          });
  
          series.push({
            name: 'Other',
            data: otherData,
          });
        }
  
        // Log the series to the console
        console.log('Series:', series);
  
        // Set the series data state
        setSeriesData(series);
  
        // Count the number of orders per city
    const ordersByCityResult: { [city: string]: number } = filteredData.reduce((result, order) => {
      const city = order.city;

      if (city) {
        if (!result[city]) {
          result[city] = 0;
        }

        result[city]++;
      }

      return result;
    }, {} as { [city: string]: number }); // Provide type annotation for the initial value

    // Log the ordersByCity to the console
    console.log('Orders by City:', ordersByCityResult);

    // Set the ordersByCity state
    setOrdersByCity(ordersByCityResult);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    // Fetch data on component mount
    useEffect(() => {
      fetchData();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Statistics" pageTitle="More" />
                    <Row>
                        <Col xxl={8}>
                            <Card>
                                <Card.Header className='align-item-center d-sm-flex'>
                                    <h5 className="card-title mb-sm-0 mt-1 flex-grow-1">Top Selling Product</h5>
                                </Card.Header>
                                <div className="card-body">
                                    {seriesData && seriesData.length > 0 ? (
                                        <TopSellingProductCharts
                                            dataColors='["--tb-primary", "--tb-primary-rgb, 0.80", "--tb-primary-rgb, 0.70", "--tb-primary-rgb, 0.60", "--tb-primary-rgb, 0.50"]'
                                            data={topSellingProductData}
                                            seriesData={seriesData}
                                        />
                                    ) : (
                                        <p>Loading...</p>
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card>
                                <Card.Header className='align-item-center d-sm-flex'>
                                    <h5 className="card-title mt-1 mb-sm-0 flex-grow-1">Orders by Countries/State</h5>
                                </Card.Header>
                                <div className="card-body">
                                    <OrdersbyCountriesCharts dataColors='["--tb-info", "--tb-info", "--tb-info", "--tb-info", "--tb-info", "--tb-info", "--tb-info","--tb-danger", "--tb-info"]' ordersByCity={ordersByCity}/>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <h5 className="card-title mb-0">Income Statistics</h5>
                                </Card.Header>
                                <div className="card-body">
                                    <IncomeStatisticsCharts dataColors='["--tb-success", "--tb-primary", "--tb-success"]' />
                                </div>
                            </Card>
                        </Col>
                    </Row>*/}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Statistics
