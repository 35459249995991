import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';

// Import Images
import logoDark from 'assets/images/logo-dark.png'
import logoLight from 'assets/images/logo-light.png'
import img1 from 'assets/images/auth/img-1.png'
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { loginUser, socialLogin, resetLoginFlag } from "slices/thunk";
import withRouter from 'Common/withRouter';

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
// import { facebook, google } from "../../config";

const Login = (props: any) => {

    document.title = "Login | AeroPartX";

    const dispatch = useDispatch<any>();
    const selectUser = (state: any) => state.Account.user;
    const selectError = (state: any) => state.Login.error;

    const selectUserData = createSelector(
        selectUser,
        selectError,
        (user, error) => ({
            user,
            error,
        })
    );
    const { user, error } = useSelector(selectUserData);

    const [userLogin, setUserLogin] = useState<any>([]);
    const [passwordShow, setPasswordShow] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.email,
                password: user.password
            });
        }
    }, [user]);

    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .required('Password is required'),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
            setLoader(true)
        }
    });

    const signIn = (type: any) => {
        dispatch(socialLogin(type, props.router.navigate));
    };

    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    useEffect(() => {
        if (rememberMe) {
            localStorage.setItem('rememberedEmail', validation.values.email);
            localStorage.setItem('rememberedPassword', validation.values.password);
        } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
        }
    }, [rememberMe, validation.values.email, validation.values.password]);

    //handleGoogleLoginResponse
    // const googleResponse = (response: any) => {
    //     signIn(response, "google");
    // };

    // //handleFacebookLoginResponse
    // const facebookResponse = (response: any) => {
    //     signIn(response, "facebook");
    // };

    //for facebook and google authentication
    const socialResponse = (type: any) => {
        signIn(type);
    };


    useEffect(() => {
        setTimeout(() => {
            dispatch(resetLoginFlag());
        }, 3000);
        setLoader(false)
    }, [dispatch, error]);

    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true}>
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="/">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22" />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <Card.Header className="custom-bg-primary border-0">
                                            <h1 className="text-white text-center fw-lighter">AeroPartX Admin</h1>
                                        </Card.Header>
                                        <Card.Body>
                                            <p className="text-muted fs-15">Sign in to continue to AeroPartX.</p>
                                            <div className="p-2">
                                                {error && error ? (<Alert variant="danger"> Email or password are invalid. </Alert>) : null}

                                                <Form action="#" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}>

                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="username">Email</Form.Label>
                                                        <Form.Control 
                                                            name="email" 
                                                            type="email" 
                                                            className={`form-control`}
                                                            id="username" 
                                                            placeholder="Enter email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <div className="text-danger">{validation.errors.email}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="float-end">
                                                            <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div>
                                                        <Form.Label htmlFor="password-input">Password</Form.Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Form.Control 
                                                                className={`form-control pe-5 password-input`}
                                                                placeholder="Enter password"           
                                                                id="password-input"
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                            />
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <div className="text-danger">{validation.errors.password}</div>
                                                            ) : null}
                                                            <Button variant='link' className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                                        </div>
                                                    </div>

                                                    <div className="form-check">
                                                        <Form.Check
                                                            type="checkbox"
                                                            value={rememberMe ? 'true' : ''}
                                                            id="auth-remember-check"
                                                            onChange={handleRememberMe}
                                                        />

                                                        <Form.Label htmlFor="auth-remember-check">Remember me</Form.Label>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button variant='primary' className="w-100 custom-primary-btn" type="submit" disabled={!error ? loader : false}>{!error ? loader && <Spinner size="sm" animation="border" className="me-2" /> : ""}Sign In</Button>
                                                    </div>

                                                </Form>

                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">©
                                            {(new Date().getFullYear())} AeroPartX. Site by <a href="https://cloudcraves.com" target="_blank" rel="noopener noreferrer">CloudCrave</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </section>
        </React.Fragment>
    );
};

export default withRouter(Login);