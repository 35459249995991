import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface TermsAndConditions {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getTermsAndConditions(): Promise<TermsAndConditions | undefined> {
    const apiUrl = '/FrontPage/Page/c10c8fbe-594b-4f52-89b1-f3ad86d12d64'; // Replace with the actual page ID

    try {
        const response: AxiosResponse<{ data: TermsAndConditions }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Terms and Conditions Page Response Data:', response.data);

        // Extract and return the terms and conditions page information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching Terms and Conditions page:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getTermsAndConditions };
