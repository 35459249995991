import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CountUp from 'react-countup';

interface SellerStatsProps {
    totalSellers: number;
    activeSellers: number;
    inactiveSellers: number;
}

const SellerStats: React.FC<SellerStatsProps> = ({ totalSellers, activeSellers, inactiveSellers }) => {
    return (
        <Row>
            <Col xxl={3} md={6}>
                <Card className="card-height-100 bg-warning-subtle border-0 overflow-hidden">
                    <div className="position-absolute end-0 start-0 top-0 z-0">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                            <g mask="url(&quot;#SvgjsMask1530&quot;)" fill="none">
                                {/* BottomLeft */}
                                <path d="M209 112L130 191" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M282 58L131 209" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M255 53L176 132" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M95 151L185 61" strokeWidth="6" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M129 230L286 73" strokeWidth="10" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M80 216L3 293" strokeWidth="6" stroke="url(#SvgjsLinearGradient1531)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M218 62L285 -5" strokeWidth="8" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                {/* TopRight */}
                                <path d="M324 10L149 185" strokeWidth="8" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M333 35L508 -140" strokeWidth="10" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M290 16L410 -104" strokeWidth="6" stroke="url(#SvgjsLinearGradient1532)" strokeLinecap="round" className="TopRight"></path>
                            </g>
                            <defs>
                                <mask id="SvgjsMask1530">
                                    <rect width="400" height="250" fill="#ffffff"></rect>
                                </mask>
                                <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1531">
                                    <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-warning-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1532">
                                    <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-warning-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <Card.Body className="p-4 z-1 position-relative">
                        <h4 className="fs-22 fw-semibold mb-3">
                            <CountUp start={0} end={totalSellers} duration={3} />
                        </h4>
                        <p className="mb-0 fw-medium text-uppercase fs-14">Total Sellers</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col xxl={3} md={6}>
                <Card className="card-height-100 bg-success-subtle border-0 overflow-hidden">
                    <div className="position-absolute end-0 start-0 top-0 z-0">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                            <g mask="url(&quot;#SvgjsMask1608&quot;)" fill="none">
                                {/* TopRight */}
                                <path d="M390 87L269 208" strokeWidth="10" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M358 175L273 260" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M319 84L189 214" strokeWidth="10" stroke="url(#SvgjsLinearGradient1609)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M327 218L216 329" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                {/* BottomLeft */}
                                <path d="M126 188L8 306" strokeWidth="8" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M220 241L155 306" strokeWidth="10" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                            </g>
                            <defs>
                                <mask id="SvgjsMask1608">
                                    <rect width="400" height="250" fill="#ffffff"></rect>
                                </mask>
                                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1609">
                                    <stop stopColor="rgba(var(--tb-success-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-success-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                                <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1610">
                                    <stop stopColor="rgba(var(--tb-success-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-success-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <Card.Body className="p-4 z-1 position-relative">
                        <h4 className="fs-22 fw-semibold mb-3">
                            <CountUp start={0} end={activeSellers} duration={3} />
                        </h4>
                        <p className="mb-0 fw-medium text-uppercase fs-14">ACTIVE SELLERS</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col xxl={3} md={6}>
                <Card className="card-height-100 bg-info-subtle border-0 overflow-hidden">
                    <div className="position-absolute end-0 start-0 top-0 z-0">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="400" height="250" preserveAspectRatio="none" viewBox="0 0 400 250">
                            <g mask="url(&quot;#SvgjsMask1551&quot;)" fill="none">
                                {/* BottomLeft */}
                                <path d="M306 65L446 -75" strokeWidth="8" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M305 123L214 214" strokeWidth="6" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M327 222L440 109" strokeWidth="6" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                <path d="M218 62L285 -5" strokeWidth="8" stroke="url(#SvgjsLinearGradient1552)" strokeLinecap="round" className="BottomLeft"></path>
                                {/* TopRight */}
                                <path d="M399 2L315 86" strokeWidth="10" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M83 77L256 -96" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M281 212L460 33" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                <path d="M257 62L76 243" strokeWidth="6" stroke="url(#SvgjsLinearGradient1553)" strokeLinecap="round" className="TopRight"></path>
                                {/* BottomLeft */}
                                <path d="M178 74L248 4" strokeWidth="10" stroke="url(#SvgjsLinearGradient1610)" strokeLinecap="round" className="BottomLeft"></path>
                            </g>
                            <defs>
                                <mask id="SvgjsMask1551">
                                    <rect width="400" height="250" fill="#ffffff"></rect>
                                </mask>
                                <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="SvgjsLinearGradient1552">
                                    <stop stopColor="rgba(var(--tb-info-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-info-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="SvgjsLinearGradient1553">
                                    <stop stopColor="rgba(var(--tb-info-rgb), 0)" offset="0"></stop>
                                    <stop stopColor="rgba(var(--tb-info-rgb), 0.2)" offset="1"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <Card.Body className="p-4 z-1 position-relative">
                        <h4 className="fs-22 fw-semibold mb-3">
                            <CountUp start={0} end={inactiveSellers} duration={3} />
                        </h4>
                        <p className="mb-0 fw-medium text-uppercase fs-14">INACTIVE SELLERS</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default SellerStats;
