import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface AboutSectionOne {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getAboutSectionOne(): Promise<AboutSectionOne | undefined> {
    const apiUrl = '/FrontPage/Page/fc946fac-cc68-4b19-a7d5-f2617fd8adb2';

    try {
        const response: AxiosResponse<{ data: AboutSectionOne }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('About Us Page Response Data:', response.data);

        // Extract and return the about us page information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching About Us page:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getAboutSectionOne };
