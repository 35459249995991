import { axiosInstance } from '../../helpers/api_helper';

const updateVendor = async (formData: any) => {
    try {
      console.log('FormData in updateVendor:', formData);
  
      const response = await axiosInstance.patch(`/Vendor/UpdateVendor`, formData);
  
      console.log('API Response from updateVendor:', response); // Log the response
  
      if (response.data.status === "00") {
        // Handle success, if needed
        return response.data;
      } else {
        // Handle error
        throw new Error('Error updating vendor');
      }
    } catch (error: any) {
      // Handle network or other errors
      throw new Error(`Error: ${error.message}`);
    }
  };
  
export { updateVendor };
