import { axiosInstance } from '../../helpers/api_helper';
import axios from 'axios';

interface ProductDetails {
  productYear: string;
  condition: string;
  yearOfManufacture: string;
  id: number;
  productID: string;
  productName: string;
  productDescription: string;
  productModel: string;
  productPartNumber: string;
  productCategories: string;
  productPrice: number;
  productManufacturers: string;
  productVendor: string;
  productImage: string;
  productStateType: number;
  productStatus: number;
  vendorID: string;
  vendorName: string;
  vendorPhone: string;
  vendorEmail: string;
  vendorAddress: string;
  country: number;
  city: string;
  vendorRCNo: string;
  logo: string;
  status: number;
  catID: string;
  catName: string;
  created: string;
  type: string;
  productDocumentation: string;
}

const baseURL = axiosInstance.defaults.baseURL;

async function fetchProductDetails(productId: string): Promise<ProductDetails> {
  const detailsApiUrl = `/Products/Details/${productId}`;
  //const imageApiUrl = `/Products/ProductImages/${productId}`;
  const imageApiUrl = `https://aeropartx.hatini.com.ng/Products/ProductImages/${productId}`;

  try {
    // Fetch product details and image together
    const [detailsResponse, imageResponse] = await Promise.all([
      axiosInstance.get(detailsApiUrl),
      //axiosInstance.get(imageApiUrl),
      axios.get(imageApiUrl),
    ]);

    const productDetails: ProductDetails = detailsResponse.data[0];

    // Attach product image URL to the product details
    const imageDetails = imageResponse.data[0];
    //productDetails.productImage = imageDetails ? `${baseURL}/${imageDetails.image}` : '';
    productDetails.productImage = imageDetails ? `https://aeropartx.hatini.com.ng/${imageDetails.image}` : '';

    return productDetails;
  } catch (error: any) {
    console.error('Error fetching product details and image:', error.message);
    throw error;
  }
}

async function getProductDocumentation(productId: string): Promise<string> {
  const documentationApiUrl = `/Products/GetProductLicense/${productId}`;

  try {
    // Fetch product documentation
    //const documentationResponse = await axiosInstance.get(documentationApiUrl);
    const documentationResponse = await axios.get(documentationApiUrl);
    const documentationDetails = documentationResponse.data[0];

    //return documentationDetails ? `${baseURL}/${documentationDetails.path}` : '';
    return documentationDetails ? `https://aeropartx.hatini.com.ng/${documentationDetails.path}` : '';
  } catch (error: any) {
    console.error('Error fetching product documentation:', error.message);
    throw error;
  }
}

export { fetchProductDetails, getProductDocumentation };
