import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { updateVendor } from 'Common/data/updatevendor';
import { fetchVendorProfileDetails, Profile } from 'Common/data/vendorprofile';
import ListViewTable from './listViewTable';
import SellerStats from './SellerStats';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation

// Define interface for seller details
interface SellerDetails {
    city: string;
    country: string;
    vendorEmail: string;
    vendorAddress: string;
    vendorRCNo: string;
    vendorID: string;
    status: number;
    initialStatusLabel?: string; // Add initialStatusLabel property
}

const SellersListView = () => {
    document.title = "List View - Sellers | AeroPartX";

    const [searchInput, setSearchInput] = useState('');
    const [modalData, setModalData] = useState({
        show: false,
        seller: null as string | null,
        sellerDetails: null as Profile | null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [sellerCounts, setSellerCounts] = useState({
        total: 0,
        active: 0,
        inactive: 0,
    });

    useEffect(() => {
        if (modalData.show && modalData.seller) {
            fetchSeller(modalData.seller);
        }
    }, [modalData.show, modalData.seller]);
    
    const toggleModal = async (vendorID: string | null) => {
        const details = vendorID ? await fetchSeller(vendorID) : null;
        setModalData({
            show: !modalData.show,
            seller: vendorID,
            sellerDetails: details,
        });
    };    

    const fetchSeller = async (vendorID: string) => {
        try {
            const profileDetails = await fetchVendorProfileDetails(vendorID);
            console.log("Profile details", profileDetails);
            return profileDetails;
        } catch (error:any) {
            console.error('Error fetching seller details:', error.message);
            return null;
        }
    };

    const handleUpdateProfile = async (values: any) => {
        try {
            setIsLoading(true);
            const updatedData = {
                vendorName: values.vendorName,
                vendorPhone: values.vendorPhone,
                city: modalData.sellerDetails?.city,
                country: modalData.sellerDetails?.country,
                vendorEmail: modalData.sellerDetails?.vendorEmail,
                vendorAddress: modalData.sellerDetails?.vendorAddress,
                vendorRCNo: modalData.sellerDetails?.vendorRCNo,
                vendorID: modalData.sellerDetails?.vendorID,
                status: values.status, // Add status field
            };
            console.log('updated Data:', updatedData);
            await updateVendor(updatedData);
            console.log('Profile updated successfully!');
            window.location.reload();
        } catch (error: any) {
            console.error('Error updating profile:', error.message);
        } finally {
            setIsLoading(false);
            toggleModal(null); 
        }
    };

    const updateCounts = (total: number, active: number, inactive: number) => {
        setSellerCounts({ total, active, inactive });
    };

    const editSeller = (vendorID: string) => {
        toggleModal(vendorID);
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        vendorName: Yup.string().required('Vendor Name is required'),
        vendorPhone: Yup.string().required('Phone is required'),
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="List View" pageTitle="Sellers" />
                    
                    <Row>
                        <SellerStats
                            totalSellers={sellerCounts.total}
                            activeSellers={sellerCounts.active}
                            inactiveSellers={sellerCounts.inactive}
                        />
                    </Row>

                    <Row id="sellersList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col lg={3}>
                                            <div className="search-box">
                                                <input 
                                                    type="text" 
                                                    className="form-control search" placeholder="Search..."
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                        <Col className="col-lg-auto ms-auto">
                                            
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <Modal className="fade" show={modalData.show} onHide={() => toggleModal(null)} centered>
                                <Modal.Header className="px-4 pt-4" closeButton>
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Edit Seller
                                    </h5>
                                </Modal.Header>
                                <Formik
                                    initialValues={{
                                        vendorName: modalData.sellerDetails?.vendorName || '',
                                        vendorPhone: modalData.sellerDetails?.vendorPhone || '',
                                        status: modalData.sellerDetails?.status || 0, // Add status field
                                    }}
                                    validationSchema={validationSchema} // Apply validation schema
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleUpdateProfile(values);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ isSubmitting, setFieldValue }) => (
                                        <FormikForm className="tablelist-form"> {/* Changed Form to FormikForm */}
                                            <Modal.Body className="p-4">
                                                <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                                                <input type="hidden" id="id-field" />

                                                <div className="mb-3">
                                                    <div className="alert alert-info" role="alert">
                                                        Seller Email is non-editable.
                                                    </div>
                                                    <Form.Label htmlFor="email-field">Seller Email</Form.Label>
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="vendorEmail"
                                                        readOnly
                                                        value={modalData.sellerDetails?.vendorEmail}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Form.Label htmlFor="seller-name-field">Seller Name</Form.Label>
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="vendorName"
                                                        placeholder="Enter Seller Name"
                                                    />
                                                    <ErrorMessage name="vendorName" component="div" className="text-danger" /> {/* Display error message */}
                                                </div>

                                                <div className="mb-3">
                                                    <Form.Label htmlFor="phone-field">Phone</Form.Label>
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="vendorPhone"
                                                        placeholder="Enter Phone"
                                                    />
                                                    <ErrorMessage name="vendorPhone" component="div" className="text-danger" /> {/* Display error message */}
                                                </div>

                                                <div className="mb-3">
                                                    <Form.Label htmlFor="status-field">Status</Form.Label>
                                                    <Form.Select 
                                                        as="select"
                                                        className="form-control"
                                                        name="status"
                                                        onChange={(e) => {
                                                            // Handle status change here if needed
                                                            const status = parseInt(e.target.value);
                                                            setFieldValue("status", status);
                                                        }}
                                                    >
                                                        <option value={2} selected={(modalData.sellerDetails?.status === 0 || modalData.sellerDetails?.status === 2)}>Inactive</option>
                                                        <option value={1} selected={modalData.sellerDetails?.status === 1}>Active</option>
                                                    </Form.Select>
                                                </div>

                                            </Modal.Body>
                                            <div className="modal-footer">
                                                <div className="hstack gap-2 justify-content-end">
                                                    <Button className="btn-ghost-danger" onClick={() => toggleModal(null)}>
                                                        Close
                                                    </Button>
                                                    <Button 
                                                        variant='primary'
                                                        type="submit"
                                                        id="add-btn" 
                                                        className="custom-primary-btn"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? (
                                                            <Spinner animation="border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        ) : (
                                                            'Edit Seller'
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </FormikForm>
                                    )}
                                </Formik>
                            </Modal>

                            <Card>
                                <Card.Body className='p-0'>
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <ListViewTable 
                                            searchInput={searchInput} 
                                            onCountsUpdate={updateCounts} 
                                            statusFilter={"All"} // You may want to change this value
                                            dateFilter={"All"} // You may want to change this value
                                            onEditSeller={editSeller}
                                        />
                                    </table>
                                    <div className="noresult" style={{ display: "none" }}>
                                        <div className="text-center py-4">
                                            <div className="avatar-md mx-auto mb-4">
                                                <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                                    <i className="bi bi-search"></i>
                                                </div>
                                            </div>
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">We've searched more than 150+ seller We did not find any seller for your search.</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SellersListView;
