import { axiosInstance } from '../../helpers/api_helper';

async function fetchOrdersCount(vendorID: string): Promise<number> {
    const apiUrl = `/Vendor/VendorOrders/${vendorID}`;

    try {
        const response = await axiosInstance.get(apiUrl);

        // Log the entire response
        console.log('API Response:', response);
        console.log('API Order List Response Data:', response.data);

        if (response.data && response.data.length > 0) {
            // Orders found
            const ordersCount = response.data.length;
            return ordersCount;
        } else {
            // Vendor orders not found
            console.warn('Vendor orders not found for vendorID:', vendorID);
            return 0; // Or handle differently based on your application logic
        }
    } catch (error: any) {
        console.error('Error:', error.message);
        // Handle errors here
        throw error; 
    }
}

export { fetchOrdersCount };
