import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import PurchaseGuide from './PurchaseGuide';
import TermsAndConditions from './TermsAndCondtions';
import { Link } from 'react-router-dom';

const GuideManagement = () => {

    document.title = "Content Management | AeroPartX";  // Update the title

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Guide Management" pageTitle="Content Management" />
                    <form id="dashboardmanagement-form" autoComplete="off" className="needs-validation" noValidate>
                        <Row>
                            <Col>
                                <PurchaseGuide />
                                <TermsAndConditions />
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default GuideManagement;
