import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Spinner, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'Common/BreadCrumb';
import { fetchCategoryList, CategoryList } from 'Common/data/category';
import { insertCategory } from 'Common/data/insertCategory';
import { updateCategory } from 'Common/data/updatecategory';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Categories = () => {
    document.title = "Categories | AeroPartX";

    const [show, setShow] = useState<boolean>(false);
    const [info, setInfo] = useState<any>([]);
    const [categoryName, setCategoryName] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [updateMessage, setUpdateMessage] = useState<string>('');
    const [deleteMessage, setDeleteMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<CategoryList[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<CategoryList | null>(null); 
    const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

    const [pagination, setPagination] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [currentpages, setCurrentpages] = useState<any>([]);
    const perPageData = 9;

    const fetchData = async () => {
        try {
            const data = await fetchCategoryList();
            setCategories(data);
        } catch (error:any) {
            console.error('Error fetching categories:', error.message);
        }
    };

    const handleAddCategory = async (values: { categoryTitle: string }) => {
        setLoading(true);
        try {
            const categoryExists = categories.some(category => category.catName.toLowerCase() === values.categoryTitle.toLowerCase());
            if (categoryExists) {
                setErrorMessage(`Category "${values.categoryTitle}" already exists.`);
            } else {
                const response = await insertCategory(values.categoryTitle);
                if (response && response.status === "00") { // Type guard to check if response is defined
                    setSuccessMessage(`Category "${values.categoryTitle}" added successfully.`);
                    fetchData(); // Fetch updated category list
                } else {
                    setErrorMessage('Failed to add category');
                }
            }
        } catch (error: any) {
            console.error('Error adding category:', error.message);
            setErrorMessage('Failed to add category. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchData();
    }, []);

    const handleEditCategory = (category: CategoryList) => {
        setSelectedCategory(category);
        setCategoryName(category.catName);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCategory(null);
        setCategoryName('');
    };

    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            if (selectedCategory) {
                const updatedCategory = await updateCategory(selectedCategory.catID.toString(), categoryName, selectedCategory.status);
                if (updatedCategory && updatedCategory.status === '00') {
                    setUpdateMessage(`Category updated successfully.`);
                    setTimeout(() => {
                        setUpdateMessage('');
                        fetchData();
                        setShowModal(false);
                    }, 3000); // Clear update message after 3 seconds

                } else {
                    console.error('Error updating category: Updated category is undefined');
                }
            }
        } catch (error: any) {
            console.error('Error updating category:', error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const handleDeleteCategory = async (category: CategoryList, status: number, index: number) => {
        setLoading(true);
        try {
            if (category) {
                // Update the category's status to the provided value (status)
                const updatedCategory = await updateCategory(category.catID.toString(), category.catName, 2);
                if (updatedCategory && updatedCategory.status === '00') {
                    setDeleteMessage(`Category "${category.catName}" deleted successfully.`);
                    setDeletingIndex(index); // Set the index of the item being deleted
                    setTimeout(() => {
                        setDeleteMessage('');
                        fetchData(); // Fetch updated category list
                        setShowModal(false);
                        setDeletingIndex(null); // Reset deleting index after deletion is complete
                    }, 3000); // Clear update message after 3 seconds
                } else {
                    console.error('Error deleting category: Updated category is undefined');
                }
            }
        } catch (error: any) {
            console.error('Error deleting category:', error.message);
        } finally {
            setLoading(false);
        }
    };  

    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;

    const currentdata = useMemo(() => categories.slice(indexOfFirst, indexOfLast), [indexOfFirst, indexOfLast])

    useEffect(() => {
        setCurrentpages(currentdata)
    }, [currentPage, currentdata])

    const searchTeamMember = (ele: any) => {
        let search = ele.target.value.toLowerCase(); // Convert search query to lowercase for case-insensitive comparison
        console.log("Search query:", search);
        if (search) {
            const filteredCategories = categories.filter((data: any) => data.catName.toLowerCase().includes(search));
            console.log("Filtered categories:", filteredCategories);
            setCurrentpages(filteredCategories);
            setPagination(false);
        } else {
            console.log("No search query");
            setCurrentpages(currentdata);
            setPagination(true);
        }
    };  
    

    const pageNumbers: any = [];
    for (let i = 1; i <= Math.ceil(categories.length / perPageData); i++) {
        pageNumbers.push(i);
    }

    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };

    const handlenextPage = () => {
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length)
        }
    }, [currentPage, pageNumbers.length])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Categories" pageTitle="Products" />
                    <Row>
                        <Col xxl={3}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Create Categories</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Formik
                                        initialValues={{ categoryTitle: '' }}
                                        validationSchema={Yup.object({
                                            categoryTitle: Yup.string().required('Category title is required'),
                                        })}
                                        onSubmit={handleAddCategory}
                                    >
                                        {({ isSubmitting }) => (
                                            <FormikForm autoComplete="off" className="needs-validation createCategory-form" id="createCategory-form">
                                                <input type="hidden" id="categoryid-input" className="form-control" value="" />
                                                <Row>
                                                    <Col xxl={12} lg={6}>
                                                        <div className="mb-3">
                                                            <label htmlFor="categoryTitle" className="form-label">Category Title<span className="text-danger">*</span></label>
                                                            <Field type="text" className="form-control" id="categoryTitle" name="categoryTitle" placeholder="Enter title" />
                                                            <ErrorMessage name="categoryTitle" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12} lg={6}></Col>
                                                    <Col xxl={12}>
                                                        <div className="text-end mb-3">
                                                            <Button variant='success' type="submit" disabled={isSubmitting || loading}>
                                                                {loading ? <Spinner animation="border" size="sm" /> : 'Add Category'}
                                                            </Button>
                                                        </div>
                                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                                        {errorMessage && <Alert variant="warning">{errorMessage}</Alert>}
                                                    </Col>
                                                </Row>
                                            </FormikForm>
                                        )}
                                    </Formik>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={9}>
                            <Row className="justify-content-between mb-4">
                                <Col xxl={3} lg={6}>
                                    <div className="search-box mb-3 mb-lg-0">
                                        <Form.Control type="text" id="searchInputList" placeholder="Search Category..." onChange={(e) => searchTeamMember(e)} />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>
                            </Row>

                            <Row id="categories-list">
                            {(pagination ? categories : currentpages).map((item: CategoryList, key: number) => (
                                <Col xxl={4} md={6} key={key}>
                                    <Card className="categrory-widgets overflow-hidden">
                                        <Card.Body className="p-4">
                                            <div className="d-flex align-items-center mb-3">
                                                <h5 className="flex-grow-1 mb-0">{item.catName}</h5>
                                                <ul className="flex-shrink-0 list-unstyled hstack gap-1 mb-0">
                                                    <li><Link to="#" className="badge bg-info-subtle text-info" onClick={() => handleEditCategory(item)}>Edit</Link></li>
                                                    <li><Link to="#" data-bs-toggle="modal" className="badge bg-danger-subtle text-danger" onClick={() => handleDeleteCategory(item, 2, key)}
                                                    >Delete</Link></li>
                                                </ul>
                                            </div>
                                            {deletingIndex === key && <Alert variant="info">{deleteMessage}</Alert>}
                                        </Card.Body>
                                    </Card>
                                </Col>))}
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>


            <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>#TB12</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="avatar-lg mx-auto">
                        <div className="avatar-title bg-light rounded">
                            <img src={info.categoryImg} alt="" className="avatar-sm overview-img" />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <h5 className="overview-title">{info.categoryTitle}</h5>
                        <p className="text-muted">by <Link to="#" className="text-reset">Admin</Link></p>
                    </div>

                    <h6 className="fs-14">Description</h6>
                    <p className="text-muted overview-desc">{info.description}</p>

                    <h6 className="fs-14 mb-3">Sub Categories</h6>
                    <ul className="vstack gap-2 mb-0 subCategory" style={{ listStyleType: "circle" }}>
                        {(info.subCategory || []).map((item: any, key: number) => (key < 4 && <li key={key}><Link to="#" className="text-reset">{item}</Link></li>))}
                    </ul>
                </Offcanvas.Body>
                <div className="p-3 border-top">
                    <Row>
                        <Col sm={6}>
                            <div data-bs-dismiss="offcanvas">
                                <Button variant="danger" type="button" className="btn btn-danger w-100 remove-list" data-bs-toggle="modal" data-bs-target="#delteModal" data-remove-id="12"><i className="ri-delete-bin-line me-1 align-bottom"></i> Delete</Button>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <Button variant="secondary" type="button" className="w-100 edit-list" data-bs-dismiss="offcanvas" data-edit-id="12"><i className="ri-pencil-line me-1 align-bottom"></i> Edit</Button>
                        </Col>
                    </Row>
                </div>
            </Offcanvas>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="categoryName">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveChanges} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                    </Button>
                </Modal.Footer>
                {updateMessage && <Alert variant="info">{updateMessage}</Alert>}

            </Modal>
        </React.Fragment >
    );
}

export default Categories;
