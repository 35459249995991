import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { account } from "Common/data";
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RecentOrders, fetchRecentOrdersList } from "../../../Common/data/orderslist";

interface RecentOrdersTableProps {
    searchInput: string;
}

const RecentOrdersTable: React.FC<RecentOrdersTableProps> = ({ searchInput }) => {

    const [orders, setOrders] = useState<RecentOrders[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderList = await fetchRecentOrdersList();
                setOrders(orderList);
                setLoading(false);
            } catch (error:any) {
                console.error('Error fetching recent orders:', error.message);
                // Handle error as needed
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredOrders = useMemo(() => {
        // Filter orders based on searchInput
        return orders.filter(order =>
            order.orderID.toLowerCase().includes(searchInput.toLowerCase()) ||
            order.fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
            order.productName.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [orders, searchInput]);

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const columns = useMemo(
        () => [
            // {
            //     Header: (<div className="form-check"> <input className="form-check-input" type="checkbox" id="checkAll" value="option" /> </div>),
            //     Cell: (cellProps: any) => {
            //        return (<div className="form-check"> <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" /> </div>);
            //    },
            //    id: '#',
            //},
            {
                Header: "Order ID",
                accessor: "orderID",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Vendor Name",
                accessor: "vendorName",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Customer Name",
                accessor: "customerName",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Part Name",
                accessor: "productName",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Order Date",
                accessor: "orderDate",
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    return <span>{formatDate(cellProps.value)}</span>;
                },
            },
            {
                Header: "Rating",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return (<React.Fragment>
                    {cellProps.rating} <i className="bi bi-star-half ms-1 text-warning fs-12"></i>
                    </React.Fragment>)
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={(columns || [])}
                data={(filteredOrders || [])}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
        </React.Fragment>
    );
};

export default RecentOrdersTable;