import { axiosInstance } from '../../helpers/api_helper';

interface Customer {
  accountStatus: number;
  name: string;
  email: string;
  phone: string;
  shippingaddress: string;
  billingaddress: string;
  profileID: string;
  state: string;
  city: string;
  status: number;
  fullName: string;
  date: string;
}

async function fetchAllCustomers(): Promise<Customer[]> {
  const apiUrl = `/Customers`;

  try {
    const response = await axiosInstance.get(apiUrl);

    // Log the entire response
    console.log('API Response:', response);
    console.log('API Response Data:', response.data);

    const customersList = response.data;

    console.log("Customers List", customersList);
    return customersList;
  } catch (error: any) {
    console.error('Error:', error.message);
    // Handle errors here
    throw error;
  }
}

export { fetchAllCustomers };    export type { Customer };

