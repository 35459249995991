import { axiosInstance } from '../../helpers/api_helper';
import avatar11 from "assets/images/users/user-dummy-img.jpg";

interface RecentOrders {
    catName: any;
    productCategories: any;
    orderID: any;
    id: number;
    purchaseID: string;
    customerName: string;
    vendorName: string;
    productImage: string; // Update the type based on the actual data type
    productName: string;
    orderDate: string;
    orderStatus: number;
    fullName: string;
    rating: number;
    avatar: string;
    country: number;
    city: string;
}

async function fetchRecentOrdersList(): Promise<RecentOrders[]> {

    const apiUrl = `/Orders`;

    try {
        const response = await axiosInstance.get(apiUrl);

        // Log the entire response
        console.log('API Response:', response);
        console.log('API Order List Response Data:', response.data);

        // Map the API response data to include the avatar property
        const recentOrders: RecentOrders[] = response.data.map((order: any) => ({
            ...order,
            avatar: avatar11,
        }));

        return recentOrders;
    } catch (error: any) {
        console.error('Error:', error.message);
        // Handle errors here
        throw error; 
    }
}

export { fetchRecentOrdersList, type RecentOrders };