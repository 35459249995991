import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface AboutCardThree {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getAboutCardThree(): Promise<AboutCardThree | undefined> {
    const apiUrl = '/FrontPage/Page/b92871d4-0909-47c6-877f-8fa1e9af9558';

    try {
        const response: AxiosResponse<{ data: AboutCardThree }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('About Us Page Response Data:', response.data);

        // Extract and return the about us page information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching About Us page:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getAboutCardThree };
