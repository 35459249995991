import React from 'react';
import { Container } from 'react-bootstrap';

// Import Components
import Breadcrumb from 'Common/BreadCrumb';
import PersonalInformation from './PersonalInformation';
import SocialMedia from './SocialMedia';
import ChangePassword from './ChangePassword';
import ApplicationNotifications from './ApplicationNotifications';
// import PrivacySecurity from './PrivacySecurity'; // Commented out PrivacySecurity component

const Settings = () => {

    document.title = "Settings | AeroPartX";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Settings" pageTitle="Accounts" />

                    <PersonalInformation />
                    <ChangePassword />
                    {/* <PrivacySecurity/> */} {/* PrivacySecurity component commented out */}

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Settings;
