import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface PurchaseGuide {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getPurchaseGuide(): Promise<PurchaseGuide | undefined> {
    const apiUrl = '/FrontPage/Page/b1f81418-439f-45df-bb7a-b8deab4c175a';

    try {
        const response: AxiosResponse<{ data: PurchaseGuide }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Purchase Guide Page Response Data:', response.data);

        // Extract and return the purchase guide page information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching Purchase Guide page:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getPurchaseGuide };
