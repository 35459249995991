import { axiosInstance } from '../../helpers/api_helper';

const insertOrder = async (formData: any) => {
  try {
    console.log('FormData in insertOrder:', formData);

    const response = await axiosInstance.post('/Orders/InsertOrder', formData);

    console.log('API Response from insertOrder:', response);

    if (response.data.status === '00') {
      
      return response.data;
    } else {
      
      console.error('Unexpected API Response:', response);
      throw new Error('Error adding product');
    }
  } catch (error: any) {
    // Log the actual error details
    console.error('Error in addProduct:', error);

    // Rethrow the error to maintain the error propagation
    throw error;
  }
};

export { insertOrder };
