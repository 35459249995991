import React, { useState } from 'react';
import { Modal, Button, Spinner, Form, Alert } from 'react-bootstrap';

interface AddOrderModalProps {
    showModal: boolean;
    onHide: () => void;
    handleAddOrderSubmit: (customerId: string) => void;
}

const AddOrderModal: React.FC<AddOrderModalProps> = ({ showModal, onHide, handleAddOrderSubmit }) => {
    const [customerId, setCustomerId] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showNote, setShowNote] = useState<boolean>(true);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerId(e.target.value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await handleAddOrderSubmit(customerId);
        setIsSubmitting(false);
        onHide(); // Close modal after form submission
    };

    return (
        <Modal show={showModal} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showNote && (
                    <Alert variant="info" onClose={() => setShowNote(false)} dismissible>
                        Provide the customer ID to proceed with the order.
                    </Alert>
                )}
                <Form.Group controlId="customerId">
                    <Form.Label>Customer ID:</Form.Label>
                    <Form.Control
                        type="text"
                        value={customerId}
                        onChange={handleInputChange}
                        placeholder="Enter Customer ID"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Add Order'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrderModal;
