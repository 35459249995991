import React, { useState, useEffect, useMemo } from 'react';
import TableContainer from "Common/TableContainer";
import { Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchProductList, ProductList } from '../../../Common/data/productlist';
import AddOrderModal from 'pages/Products/Modal';

interface ProductTableProps {
    searchInput: string;
}

const ProductTable: React.FC<ProductTableProps> = ({ searchInput }) => {
    const [productList, setProductList] = useState<ProductList[]>([]);
    const [showAddOrderModal, setShowAddOrderModal] = useState(false);

    useEffect(() => {
        const fetchProductListData = async () => {
            try {
                const productData = await fetchProductList();
                setProductList(productData);
            } catch (error: any) {
                console.error('Error fetching product list:', (error as Error).message);
            }
        };

        fetchProductListData();
    }, []);

    const filteredProductList = useMemo(() => {
        return productList.filter(product =>
            product.productName.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [productList, searchInput]);

    const handleAddOrderSubmit = async (customerId: string) => {
        // Logic for submitting the order
        setShowAddOrderModal(false);
    };

    const columns = useMemo(() => [
        {
            Header: "Product ID",
            accessor: "productID",
            disableFilters: true,
            filterable: true,
        },
        {
            Header: "Vendor Name",
            accessor: "vendorName",
            disableFilters: true,
            filterable: true,
        },
        {
            Header: "Part Name",
            Cell: (product: any) => (
                <>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar-title bg-light rounded">
                                <img
                                    src={product.row.original.productImage}
                                    alt=""
                                    className="avatar-xs"
                                />
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                <Link
                                    to={`/product-overview/${product.row.original.productID}`}
                                    className="d-block text-reset"
                                >
                                    {product.row.original.productName}
                                </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                Category :{" "}
                                <span className="fw-medium">
                                    {product.row.original.catName}
                                </span>
                            </p>
                        </div>
                    </div>
                </>
            ),
        },
    ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={(columns || [])}
                data={(filteredProductList || [])}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
            {/* AddOrderModal component */}
            <AddOrderModal
                showModal={showAddOrderModal}
                onHide={() => setShowAddOrderModal(false)}
                handleAddOrderSubmit={handleAddOrderSubmit}
            />
        </React.Fragment>
    );
};

export default ProductTable;
