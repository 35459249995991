// ProductFilter.tsx
import React, { useState, useEffect } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductCategoryFilter from './ProductCategoryFilter';
import ProductTable from './ProductTable';

const ProductFilter = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filterKey, setFilterKey] = useState<number>(0); // Add a key to force re-rendering of ProductCategoryFilter

  const handleCategoryChange = (categories: string[]) => {
    setSelectedCategories(categories);
  };

  const clearAllFilters = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // Prevent the default action of the link
    setSelectedCategories([]);
    // Increment the key to force re-rendering of ProductCategoryFilter
    setFilterKey((prevKey) => prevKey + 1);
  };

  return (
    <React.Fragment>
      <Col xl={3} lg={4}>
        <Card className="overflow-hidden">
          <Card.Header>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Filters</h5>
              </div>
              <div className="flex-shrink-0">
                <Link to="#" className="text-decoration-underline" id="clearall" onClick={(e) => clearAllFilters(e)}>
                  Clear All
                </Link>
              </div>
            </div>
          </Card.Header>

          {/* Pass key and selectedCategories to ProductCategoryFilter */}
          <ProductCategoryFilter key={filterKey} onCategoryChange={handleCategoryChange} clearAllFilters={clearAllFilters} />
        </Card>
      </Col>

      <Col xl={9} lg={8}>
        {/* Pass selectedCategories to ProductTable */}
        <ProductTable selectedCategories={selectedCategories} />
      </Col>
    </React.Fragment>
  );
};

export default ProductFilter;
