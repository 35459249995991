import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface Category {
    id: number;
    name: string;
    createdAt: string;
    status: string;
}

async function insertCategory(name: string): Promise<Category | undefined> {
    const apiUrl = '/Categories/AddCategory';

    try {
        const response: AxiosResponse<Category> = await axiosInstance.post(apiUrl, { name });

        // Log the entire response data
        console.log('Inserted Category Response Data:', response);

        // Return the inserted category information from the data property of the response
        return response.data;
    } catch (error: any) {
        console.error('Error inserting category:', error.message);
        // Handle errors here
        throw error;
    }
}

export { insertCategory };
