import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface BannerThreeInfoCard {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getBannerThreeInfoCard(): Promise<BannerThreeInfoCard | undefined> {
    const apiUrl = '/FrontPage/Page/8b9273e7-41ac-45fc-8227-474ea03fac3b'; // Replace with the correct API endpoint

    try {
        const response: AxiosResponse<{ data: BannerThreeInfoCard }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Banner Three Info Card Response Data:', response.data);

        // Extract and return the banner three info card information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching Banner Three Info Card:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getBannerThreeInfoCard };
