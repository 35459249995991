import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown } from 'react-bootstrap';
import CustomDropdownToggle from 'Common/CustomDropdownToggle';
import { RecentOrders, fetchRecentOrdersList } from 'Common/data/orderslist';

interface CategoriesData {
    sortedCatNames: string[];
    catNamePercentages: Record<string, number>;
}

const getCategoryColor = (index: number): string => {
    const colors = ['bg-success', 'bg-warning', 'bg-danger', 'bg-info', 'bg-primary', 'bg-secondary', 'bg-dark'];
    return colors[index % colors.length];
};

const TopProducts = () => {
    const [categoriesData, setCategoriesData] = useState<CategoriesData>({
        sortedCatNames: [],
        catNamePercentages: {},
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersList = await fetchRecentOrdersList();

                const catNameCounts: Record<string, number> = {};
                const totalOrders = ordersList.length;

                ordersList.forEach((order) => {
                    const { catName } = order;
                    catNameCounts[catName] = (catNameCounts[catName] || 0) + 1;
                });

                const catNamePercentages: Record<string, number> = {};

                Object.keys(catNameCounts).forEach((catName) => {
                    const count = catNameCounts[catName];
                    const percentage = (count / totalOrders) * 100;
                    catNamePercentages[catName] = percentage;
                });

                const sortedCatNames = Object.keys(catNamePercentages).sort(
                    (a, b) => catNamePercentages[b] - catNamePercentages[a]
                );

                setCategoriesData({
                    sortedCatNames,
                    catNamePercentages,
                });

            } catch (error:any) {
                console.error('Error fetching recent orders:', error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Col xxl={3} lg={6}>
                <Card className="card-height-100">
                    <Card.Header className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Highest Demanded Category</h4>
                        <div className="flex-shrink-0">
                            <Dropdown className="card-header-dropdown">
                                <Dropdown.Toggle as={CustomDropdownToggle} href="#" className="text-reset dropdown-btn">
                                    <span className="text-muted">Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item>Download Report</Dropdown.Item>
                                    <Dropdown.Item>Export</Dropdown.Item>
                                    <Dropdown.Item>Import</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>

                    {categoriesData.sortedCatNames.length > 0 && (
                        <Card.Body>
                            {categoriesData.sortedCatNames.map((catName) => {
                                const percentage = categoriesData.catNamePercentages[catName];
                                const progressColor = getCategoryColor(categoriesData.sortedCatNames.indexOf(catName));

                                return (
                                    <div key={catName} className="mb-4">
                                        <span className="badge bg-dark-subtle text-body float-end">{percentage.toFixed(2)}%</span>
                                        <h6 className="mb-2">{catName}</h6>
                                        <div className={`progress progress-sm`} role="progressbar">
                                            <div className={`progress-bar ${progressColor} bg-opacity-50 progress-bar-striped progress-bar-animated`} style={{ width: `${percentage}%` }}></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Card.Body>
                    )}
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TopProducts;
