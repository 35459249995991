import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

// Set up the base URL and default headers
const baseURL = 'https://aeropartx.hatini.com.ng';

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {

    if (Array.isArray(response.data)) {
      return response;
    } else {
      return response.data !== undefined ? response.data : response;
    }
  },
  function (error) {
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        case 404:
          message = 'Sorry! The data you are looking for could not be found';
          break;
        default:
          message = error.response.data || error.response.statusText;
      }
    } else if (error.request) {
      message = 'No response from the server';
    } else {
      message = error.message || 'An error occurred';
    }
    return Promise.reject(message);
  }
);


/**
 * Sets the default authorization
 * @param {string} token
 */
const setAuthorization = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

class APIClient {
  /**
   * Fetches data from the given URL
   */

  get = (url: string, params?: AxiosRequestConfig['params']) => {
    return axiosInstance.get(url, { params });
  };

  /**
   * Posts given data to URL
   */
  create = (url: string, data: any) => {
    return axiosInstance.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axiosInstance.patch(url, data);
  };

  put = (url: string, data: any) => {
    return axiosInstance.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string, config?: AxiosRequestConfig) => {
    return axiosInstance.delete(url, config);
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser, axiosInstance };