import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Row, Table } from 'react-bootstrap';
import Acitivity from './Activity';
import RecentOrders from './RecentOrders';
import { Link } from 'react-router-dom';
import { fetchProfileDetails } from "../../../Common/data/profile";
import { getStoreDetails, StoreDetails } from 'Common/data/storedetails';

// Import Images
import img1 from 'assets/images/users/user-dummy-img.jpg'

const Profile = () => {

    const [profile, setProfile] = useState<any>(null);
    const [storeDetails, setStoreDetails] = useState<StoreDetails | undefined>(undefined);

    const fetchStoreDetails = async () => {
        try {
            const details: StoreDetails[] | undefined = await getStoreDetails();
            setStoreDetails(details && details.length > 0 ? details[0] : undefined);
        } catch (error: any) {
            console.error('Error fetching store details:', error.message);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileData = await fetchProfileDetails();
                setProfile(profileData);

            } catch (error: any) {
                console.error('Error fetching profile details:', error.message);
                // Handle errors here
            }
        };

        fetchData();
        fetchStoreDetails();
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col xxl={9}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={3}>
                                    <div className="profile-user-img position-relative">
                                        <img src={img1} alt="" className="rounded object-fit-cover" />
                                        <span className="position-absolute top-0 start-100 translate-middle badge border border-3 border-white rounded-circle bg-success p-1 mt-1 me-1"><span className="visually-hidden">unread messages</span></span>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <div className="d-flex border-bottom border-bottom-dashed pb-3 mb-3 mt-4 mt-lg-0">
                                        <div className="flex-grow-1">
                                            <h5>{profile?.name}</h5>
                                            <p className="text-muted mb-0">Admin</p>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col lg={6}>
                                            <div className="table-responsive">
                                                <Table className="table-borderless table-sm mb-0">
                                                    <tbody>
                                                        
                                                        <tr>
                                                            <td>
                                                                Email
                                                            </td>
                                                            <td className="fw-medium">
                                                                {profile?.email}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Mobile / Phone No.
                                                            </td>
                                                            <td className="fw-medium">
                                                                {profile?.phone}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mt-3">
                                        <ul className="list-unstyled hstack gap-2 mb-0">
                                            <li>
                                                Social Media:
                                            </li>
                                            <li>
                                                <Link to={`mailto:${storeDetails?.shopEmail}`} className="btn btn-soft-primary btn-icon btn-sm"><i className="ph-envelope"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com/company/aeropartx/" className="btn btn-soft-secondary btn-icon btn-sm"><i className="ph-linkedin-logo"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.instagram.com/aeropart_x" target="_blank" className="btn btn-soft-danger btn-icon btn-sm"><i className="ph-instagram-logo"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <RecentOrders />
                </Col>

            </Row>
        </React.Fragment>
    );
};

export default Profile;