import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface BannerOneInfoCard {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getBannerOneInfoCard(): Promise<BannerOneInfoCard | undefined> {
    const apiUrl = '/FrontPage/Page/5f2ac32f-af7b-4bb2-a88d-c49814c24e77'; // Replace with the correct API endpoint

    try {
        const response: AxiosResponse<{ data: BannerOneInfoCard }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Banner One Info Card Response Data:', response.data);

        // Extract and return the banner one info card information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching Banner One Info Card:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getBannerOneInfoCard };
