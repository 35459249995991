import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface SliderData {
    id: number;
    createdAt: string;
    userID: string;
    sliderID: string;
    sliderTitle: string;
    sliderSubTitle: string;
    sliderContent: string;
    sliderBtnColor: string;
    sliderImage: string;
    sliderStatus: number;
}

async function getSliderDetails(): Promise<SliderData[] | undefined> {
    const apiUrl = '/FrontPage/sliders';

    try {
        const response: AxiosResponse<SliderData[]> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Slider Details Response Data:', response.data);

        // Extract and return the slider details
        return response.data;
    } catch (error: any) {
        console.error('Error fetching slider details:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getSliderDetails };
