import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

interface Profile {
    accountStatus: any;
    id: number;
    vendorID: string;
    vendorName: string;
    vendorPhone: string;
    vendorEmail: string;
    vendorPassword: string;
    vendorAddress: string;
    country: string; 
    city: string;
    vendorRCNo: string;
    logo: string;
    status: number;
    Created: string;
}

// Function to fetch profile details
async function fetchVendorProfileDetails(vendorId: string): Promise<Profile | null> {
    const apiUrl = `/Vendor/Details/${vendorId}`;
  
    try {
        const response: AxiosResponse<Profile[]> = await axiosInstance.get(apiUrl);
  
        // Log the entire response
        console.log('Profile API Response:', response);
        console.log('Profile API Response Data:', response.data);
  
        // Use the data directly, assuming it is an array of Profile objects
        const profile = response.data[0];
  
        return profile || null;
    } catch (error: any) {
        console.error('Error fetching profile details:', error.message);
        // Handle errors here
        throw error;
    }
}

export { fetchVendorProfileDetails, type Profile };
