import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { ProductList, fetchProductList } from 'Common/data/productlist';
import { RecentOrders, fetchRecentOrdersList } from 'Common/data/orderslist';
import { fetchAllCustomers, Customer } from 'Common/data/allCustomers';

interface WidgetsProps {
    id : number;
    name : string;
    amount : number;
    decimal ?: number;
    perstange : string;
    badgeColor : string;
    icon : string;
    iconColor : string;
}

const Widgets = () => {
    const [productList, setProductList] = useState<ProductList[]>([]);
    const [orders, setOrders] = useState<RecentOrders[]>([]);
    const [customers, setCustomers] = useState<Customer[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const products = await fetchProductList();
                const filteredProducts = products.filter(product => product.productStatus !== 0);
                setProductList(filteredProducts);

                const recentOrders = await fetchRecentOrdersList();
                setOrders(recentOrders);

                const allCustomers = await fetchAllCustomers();
                setCustomers(allCustomers);
            } catch (error: any) {
                console.error('Error fetching product list:', error.message);
            }
        };

        fetchData();
    }, []);

    const widgetsData : Array<WidgetsProps> = [
        // {
        //     id: 1,
        //     name: "TOTAL EARNINGS",
        //     amount: 0,
        //     decimal: 2,
        //     perstange: "0.00%",
        //     badgeColor: "success",
        //     icon: "ph-wallet",
        //     iconColor: "secondary"
        // },
        {
            id : 2,
            name : "ORDERS",
            amount : orders.length,
            perstange : orders.length > 1 ? `+${((orders.length - 1) * 100 / (orders.length - 1)).toFixed(2)}` : "+0.00",
            badgeColor : "danger",
            icon : "ph-storefront",
            iconColor : "info"
        },
        {
            id : 3,
            name : "CUSTOMERS",
            amount : customers.length,
            perstange : customers.length > 0 ? `+${((customers.length - 1) * 100 / (customers.length - 1)).toFixed(2)}` : "+0.00",
            badgeColor : "success",
            icon : "ph-user-circle",
            iconColor : "warning"
        },
        {
            id : 4,
            name : "PRODUCTS AVAILABLE",
            amount : productList.length,
            perstange : productList.length > 0 ? `+${((productList.length - 1) * 100 / (productList.length - 1)).toFixed(2)}` : "+0.00",
            badgeColor : "success",
            icon : "ph-sketch-logo",
            iconColor : "primary"
        },
    ];
    return (
        <React.Fragment>
            {(widgetsData || []).map((item : any, key : number) => (
            <Col key={key}>
                <Card className="card-animate">
                    <Card.Body>
                        <div className="d-flex justify-content-between">
                            <div className={"vr rounded bg-" + item.iconColor + " opacity-50"} style={{ width: "4px"}}></div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-medium text-muted fs-14 text-truncate">{item.name}</p>
                                <h4 className="fs-22 fw-semibold mb-3">{item.decimal ? "N" : ""}<span className="counter-value" data-target="98851.35">
                                    <CountUp start={0} end={item.amount} separator="," decimals={item.decimal && 2} />
                                    </span></h4>
                                <div className="d-flex align-items-center gap-2">
                                    <h5 className={"mb-0 badge bg-" + item.badgeColor + "-subtle text-" + item.badgeColor}>
                                        <i className={item.badgeColor === "success" ? "ri-arrow-right-up-line align-bottom" : "ri-arrow-right-down-line align-bottom"}></i> {item.perstange} %
                                    </h5>
                                    <p className="text-muted mb-0">than last week</p>
                                </div>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className={"avatar-title bg-" + item.iconColor + "-subtle text-" + item.iconColor + " rounded fs-3"}>
                                    <i className={item.icon}></i>
                                </span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            ))}
        </React.Fragment>
    );
}

export default Widgets;