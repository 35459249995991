import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface BannerTwoInfoCard {
    id: number;
    pageID: string;
    pageName: string;
    pageContent: string;
    createdBy: string;
    updatedBy: string;
    updated: string;
    createdAt: string;
    pageStatus: number;
}

async function getBannerTwoInfoCard(): Promise<BannerTwoInfoCard | undefined> {
    const apiUrl = '/FrontPage/Page/811f69d7-0354-491a-9129-5c8d41ac0c3b'; // Replace with the correct API endpoint

    try {
        const response: AxiosResponse<{ data: BannerTwoInfoCard }> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Banner Two Info Card Response Data:', response.data);

        // Extract and return the banner two info card information
        return response.data.data;
    } catch (error: any) {
        console.error('Error fetching Banner Two Info Card:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getBannerTwoInfoCard };
