import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

interface Profile {
  id: number;
  vendorID: string;
  vendorName: string;
  vendorPhone: string;
  vendorEmail: string;
  vendorPassword: string; // Add this property if needed
  vendorAddress: string;
  country: number;
  city: string;
  vendorRCNo: string;
  logo: string;
  status: number;
}

const authUser = localStorage.getItem('authUser');
const { data } = authUser ? JSON.parse(authUser) : { data: { profileID: '' } };
const profileID = data ? data.profileID : ''; // Ensure data is defined before destructuring
console.log("Profile ID", profileID);

// Function to fetch profile details
async function fetchProfileDetails(): Promise<Profile> {
  const apiUrl = `/Admin/${profileID}`;

  try {
    const response: AxiosResponse<Profile> = await axiosInstance.get(apiUrl);

    // Log the entire response
    console.log('Profile API Response:', response);
    console.log('Profile API Response Data:', response.data);

    // Use the data directly, assuming it is a single Profile object
    const profile = response.data;

    return profile;
  } catch (error: any) {
    console.error('Error fetching profile details:', error.message);
    // Handle errors here
    throw error;
  }
}

export { fetchProfileDetails, type Profile };
