import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // Import Yup for form validation
import Breadcrumb from 'Common/BreadCrumb';
import TableContainer from "Common/TableContainer";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { Customer, fetchAllCustomers } from "../../Common/data/allCustomers";
import { updateBuyerStatus } from 'Common/data/updateBuyerStatus';

const UsersList = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [loading, setLoading] = useState(true);
    const [totalCustomers, setTotalCustomers] = useState<number>(0);
    const [activeCustomers, setActiveCustomers] = useState<number>(0);
    const [inactiveCustomers, setInactiveCustomers] = useState<number>(0);
    const [selectedUser, setSelectedUser] = useState<Customer | null>(null);
    const [searchInput, setSearchInput] = useState<string>('');
    const [modal_AddUserModals, setmodal_AddUserModals] = useState<boolean>(false);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string>('');

    document.title = "Users List | AeroPartX";

    const fetchData = async () => {
        try {
            const customersList = await fetchAllCustomers();
            setCustomers(customersList);
            setLoading(false);
        } catch (error:any) {
            console.error('Error fetching sellers list:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const totalCustomers = customers.length;
        const activeCustomers = customers.filter((customer) => customer.status === 1).length;
        const inactiveCustomers = customers.filter((customer) => customer.status === 0).length;

        setTotalCustomers(totalCustomers);
        setActiveCustomers(activeCustomers);
        setInactiveCustomers(inactiveCustomers);
    }, [customers]);

    const filteredCustomers: Customer[] = useMemo(() => {
        return customers.filter(customer =>
            (customer.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
            customer.phone?.toLowerCase().includes(searchInput.toLowerCase()) ||
            customer.fullName?.toLowerCase().includes(searchInput.toLowerCase()) ||
            customer.city?.toLowerCase().includes(searchInput.toLowerCase()))
        );
    }, [customers, searchInput]);    

    const editUser = (user: Customer) => {
        setSelectedUser(user);
        setmodal_AddUserModals(true);
    }

    const handleCloseModal = () => {
        setSelectedUser(null);
        setmodal_AddUserModals(false);
    }

    const handleSubmit = async (values: any, actions: any) => {
        setIsSaving(true);
        setSaveError('');
    
        try {
            // Find the selected user from the customers state
            const selectedCustomer = customers.find(customer => customer.profileID === selectedUser?.profileID);
    
            console.log("Selected Customer:", selectedCustomer);
    
            // Create the payload with data from values and selectedCustomer
            const payload = {
                status: values.accountStatus,
                customerID: selectedUser?.profileID
            };
    
            console.log("Payload before sending:", payload);
    
            const response = await updateBuyerStatus(payload);
    
            if (response.status === "00") {
                setmodal_AddUserModals(false);
                fetchData();
                //window.location.reload();
            } else {
                throw new Error('Error updating profile');
            }
        } catch (error:any) {
            setSaveError(error.message);
        } finally {
            setIsSaving(false);
            actions.setSubmitting(false);
        }
    }       

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Users List" pageTitle="More" />

                    <Row id="usersList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-lg-2 g-4">
                                        <Col lg={3}>
                                            <div className="search-box">
                                                <input 
                                                    type="text" 
                                                    className="form-control search" 
                                                    placeholder="Search for users..." 
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>

                                        {isMultiDeleteButton && <Button variant="danger" className="btn-icon"><i className="ri-delete-bin-2-line"></i></Button>}

                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body className='p-0'>
                                    <TableContainer
                                        columns={[
                                            {
                                                Header: "Customer ID",
                                                accessor: "profileID",
                                                disableFilters: true,
                                                filterable: true,
                                            },
                                            {
                                                Header: "User Name",
                                                accessor: "fullName",
                                                disableFilters: true,
                                                filterable: true,
                                            },
                                            {
                                                Header: "Email",
                                                accessor: "email",
                                                disableFilters: true,
                                                filterable: true,
                                            },
                                            {
                                                Header: "Account Status",
                                                disableFilters: true,
                                                filterable: true,
                                                accessor: (cellProps: any) => {
                                                    const accountStatus = cellProps.status; // Assuming "status" is the correct property name
                                                    const statusText = accountStatus === 1 ? "Active" : "Inactive";

                                                    return (
                                                        <span className={`badge ${accountStatus === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'} text-uppercase`}>
                                                            {statusText}
                                                        </span>
                                                    );
                                                },
                                            },
                                            {
                                                Header: "Action",
                                                Cell: (cellProps: any) => (
                                                    <Button onClick={() => editUser(cellProps.row.original)} className="btn btn-sm btn-soft-secondary">{isSaving && selectedUser === cellProps.row.original ? <Spinner animation="border" size="sm" /> : "Edit"}</Button>
                                                )
                                            },                                            
                                        ]}
                                        data={filteredCustomers}
                                        iscustomPageSize={false}
                                        isBordered={false}
                                        customPageSize={10}
                                        className="custom-header-css table align-middle table-nowrap"
                                        tableClass="table-centered align-middle table-nowrap mb-0"
                                        theadClass="text-muted table-light"
                                        SearchPlaceholder='Search Products...'
                                    />
                                    <div className="noresult" style={{ display: "none" }}>
                                        <div className="text-center">
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for you search.</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Modal className="fade" show={modal_AddUserModals} onHide={handleCloseModal} centered>
                        <Modal.Header className="px-4 pt-4" closeButton>
                            <h5 className="modal-title" id="exampleModalLabel">{selectedUser ? 'Edit User' : 'Add User'}</h5>
                        </Modal.Header>
                        <Formik
                            initialValues={{
                                fullName: selectedUser?.fullName || '',
                                email: selectedUser?.email || '',
                                accountStatus: selectedUser?.status === 1 ? 'Active' : 'Inactive'
                            }}
                            validationSchema={Yup.object().shape({
                                fullName: Yup.string().required('Full Name is required'),
                                email: Yup.string().email('Invalid email').required('Email is required'),
                                accountStatus: Yup.string().required('Account Status is required')
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <FormikForm className="tablelist-form">
                                    <Modal.Body className="p-4">
                                        <div id="alert-error-msg" className="d-none alert alert-danger py-2">{saveError}</div>
                                        <input type="hidden" id="id-field" />

                                        <div className="mb-3">
                                            <Form.Label htmlFor="user-name">User Name</Form.Label>
                                            <Field
                                                type="text"
                                                id="user-name-field"
                                                name="fullName"
                                                placeholder="Enter Name"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="fullName" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="email-field">User Email</Form.Label>
                                            <Field
                                                type="email"
                                                id="email-field"
                                                name="email"
                                                placeholder="Enter Email"
                                                className="form-control"
                                                readOnly
                                            />
                                            {selectedUser && <small className="text-muted">Email is not editable.</small>}
                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </div>

                                        <div>
                                            <label htmlFor="account-status" className="form-label">
                                                Account Status
                                            </label>
                                            <Form.Select
                                                as="select"
                                                name="accountStatus"
                                                className="form-select"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    // Handle status change here if needed
                                                    const status = parseInt(e.target.value);
                                                    setFieldValue("accountStatus", status);
                                                }}
                                            >
                                                <option value={1} selected={selectedUser?.status === 1}>Active</option>
                                                <option value={2} selected={(selectedUser?.status === 0 || selectedUser?.status === 2)}>Inactive</option>
                                            </Form.Select>
                                            <ErrorMessage name="accountStatus" component="div" className="text-danger" />
                                        </div>
                                        
                                    </Modal.Body>
                                    <div className="modal-footer">
                                        <div className="hstack gap-2 justify-content-end">
                                            <Button className="btn-ghost-danger" onClick={handleCloseModal}>Close</Button>
                                            <Button variant='success' type="submit" className="custom-primary-btn" disabled={isSaving || isSubmitting}>
                                                {isSaving ? <Spinner animation="border" size="sm" /> : selectedUser ? 'Save Changes' : 'Add User'}
                                            </Button>
                                        </div>
                                    </div>
                                </FormikForm>
                            )}
                        </Formik>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default UsersList;
