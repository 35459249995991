import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface StoreDetails {
    storeID: string;
    storeName: string;
    storeLocation: string;
    storeDescription: string;
    storeLogo: string;
    shopEmail: string;
    shopPhone: string;
}

async function getStoreDetails(): Promise<StoreDetails[] | undefined> {
  const apiUrl = '/storedetails';

  try {
    const response: AxiosResponse<StoreDetails[]> = await axiosInstance.get(apiUrl);

    // Log the entire response data
    console.log('Store Response Data:', response.data[0]);

    // Extract and return the store details
    return response.data;
  } catch (error: any) {
    console.error('Error fetching store details:', error.message);
    // Handle errors here
    throw error;
  }
}

export { getStoreDetails };
