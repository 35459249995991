import React, {useState} from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateProduct = () => {

    document.title = "Create Product | AeroPartX";

    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    /* Formats the size */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Create Product" pageTitle="Products" />
                    <form id="createproduct-form" autoComplete="off" className="needs-validation" noValidate>
                        <Row>
                            <Col xl={9} lg={8}>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                        <i className="bi bi-box-seam"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Product Information</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="mb-3">

                                            <Form.Label htmlFor="product-title-input">Product title</Form.Label>
                                            <Form.Control type="hidden" id="formAction" name="formAction" defaultValue="add" />
                                            <Form.Control type="text" className="d-none" id="product-id-input" />
                                            <Form.Control type="text" id="product-title-input" defaultValue="" placeholder="Enter product title" required />
                                            
                                            <div className="invalid-feedback">Please enter a product title.</div>
                                        </div>
                                        <div className="mb-3">
                                            <Form.Label>Product description</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data="<p>Introducing our Aviation Engine Part Collection: precision-engineered components crafted from top-grade alloys. Designed for efficiency and reliability, these parts offer seamless integration into various aircraft models.</p>
                                                        <ul>
                                                            <li>Premium Materials</li>
                                                            <li>Cutting-Edge Engineering</li>
                                                            <li>Rigorous Quality Control</li>
                                                            <li>Sleek and Functional Design</li>
                                                        </ul>"
                                                onReady={(editor:any) => {
                                                    // You can store the "editor" and use when it is needed.
                                                }}
                                                onChange={(editor:any) => {
                                                    editor.getData();
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <div className="d-flex align-items-start">
                                                <div className="flex-grow-1">
                                                    <Form.Label>Product category</Form.Label>
                                                </div>
                                            </div>
                                            <div>
                                                <select className="form-select" id="choices-category-input" name="choices-category-input">
                                                    <option value="">Select product category</option>
                                                    <option value="Appliances">Aircraft Engines</option>
                                                    <option value="Automotive Accessories">Avionics</option>
                                                    <option defaultValue="Electronics">Airframe Components</option>
                                                    <option value="Fashion">Interior and Cabin</option>
                                                    <option value="Furniture">Propellers and Rotors</option>
                                                    <option value="Grocery">Instruments and Gauges</option>
                                                    <option value="Kids">Safety and Survival</option>
                                                    <option value="Watches">Tools and Equipment</option>
                                                </select>
                                            </div>
                                            <div className="error-msg mt-1">Please select a product category.</div>
                                        </div>

                                        <div className="mt-3">

                                            <Form.Label htmlFor="product-title-input">Aircraft Model</Form.Label>
                                            <Form.Control type="hidden" id="formAction" name="formAction" defaultValue="add" />
                                            <Form.Control type="text" className="d-none" id="product-id-input" />
                                            <Form.Control type="text" id="product-title-input" defaultValue="" placeholder="Enter aircraft model" required />
                                            
                                            <div className="invalid-feedback">Please enter an aircraft model.</div>
                                        </div>

                                        <div className="mt-3">

                                            <Form.Label htmlFor="product-title-input">Aircraft Type</Form.Label>
                                            <Form.Control type="hidden" id="formAction" name="formAction" defaultValue="add" />
                                            <Form.Control type="text" className="d-none" id="product-id-input" />
                                            <Form.Control type="text" id="product-title-input" defaultValue="" placeholder="Enter an aircraft type" required />
                                            
                                            <div className="invalid-feedback">Please enter an aircraft type.</div>
                                        </div>

                                        <div className="my-3">

                                            <Form.Label htmlFor="product-title-input">Year of Manufacture</Form.Label>
                                            <Form.Control type="hidden" id="formAction" name="formAction" defaultValue="add" />
                                            <Form.Control type="text" className="d-none" id="product-id-input" />
                                            <Form.Control type="text" id="product-title-input" defaultValue="" placeholder="Enter a year of manufacture" required />
                                            
                                            <div className="invalid-feedback">Please enter a year of manufacture.</div>
                                        </div>

                                        <div>
                                            <div className="d-flex align-items-start">
                                                <div className="flex-grow-1">
                                                    <Form.Label>Condition</Form.Label>
                                                </div>
                                            </div>
                                            <div>
                                                <select className="form-select" id="choices-category-input" name="choices-category-input">
                                                    <option value="">Select condition</option>
                                                    <option value="Appliances">Condition</option>
                                                    <option value="Automotive Accessories">FN</option>
                                                    <option defaultValue="Electronics">NS</option>
                                                    <option value="Fashion">OH</option>
                                                    <option value="Furniture">RP</option>
                                                    <option value="Grocery">SV</option>
                                                    <option value="Kids">AR</option>
                                                </select>
                                            </div>
                                            <div className="error-msg mt-1">Please select a product category.</div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                        <i className="bi bi-images"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Product Gallery</h5>
                                                <p className="text-muted mb-0">Add product gallery images.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="dropzone my-dropzone">
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles);
                                                }}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone dz-clickable text-center">
                                                        <div className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                            </div>
                                                            <h5>Drop files here or click to upload.</h5>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="list-unstyled mb-0" id="file-previews">
                                                {selectedFiles.map((f: any, i: number) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-preview dz-processing dz-image-preview dz-success dz-image  dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <div className='image'>
                                                                        <img
                                                                            // data-dz-thumbnail=""
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="error-msg mt-1">Please add a product images.</div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                        <i className="bi bi-file-earmark-text"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Quality Control Documentation</h5>
                                                <p className="text-muted mb-0">Upload Quality Control Documentation.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="dropzone my-dropzone">
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles);
                                                }}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone dz-clickable text-center">
                                                        <div className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                            </div>
                                                            <h5>Drop files here or click to upload.</h5>
                                                            <p className='fs-6 text-muted'>Document should be in .pdf </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="list-unstyled mb-0" id="file-previews">
                                                {selectedFiles.map((f: any, i: number) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-preview dz-processing dz-image-preview dz-success dz-image  dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <div className='image'>
                                                                        <img
                                                                            // data-dz-thumbnail=""
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="error-msg mt-1">Please upload Quality Control Documentation.</div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                        <i className="bi bi-file-earmark-text"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Maintenance Documentation</h5>
                                                <p className="text-muted mb-0">Upload Maintenance Documentation.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="dropzone my-dropzone">
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles);
                                                }}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone dz-clickable text-center">
                                                        <div className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                            </div>
                                                            <h5>Drop files here or click to upload.</h5>
                                                            <p className='fs-6 text-muted'>Document should be in .pdf </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="list-unstyled mb-0" id="file-previews">
                                                {selectedFiles.map((f: any, i: number) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-preview dz-processing dz-image-preview dz-success dz-image  dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <div className='image'>
                                                                        <img
                                                                            // data-dz-thumbnail=""
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="error-msg mt-1">Please upload Maintenance Documentation.</div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                        <i className="bi bi-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">General Information</h5>
                                                <p className="text-muted mb-0">Fill information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col lg={3} sm={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="orders-input">Part number</Form.Label>
                                                    <Form.Control type="text" id="orders-input" placeholder="Part number" required />
                                                    <div className="invalid-feedback">Please enter part number.</div>
                                                </div>
                                            </Col>

                                            <Col lg={3} sm={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="stocks-input">FAA</Form.Label>
                                                    <Form.Control type="text" id="stocks-input" placeholder="FAA" required />
                                                    <div className="invalid-feedback">Please enter FAA standard.</div>
                                                </div>
                                            </Col>
                                            
                                            <Col lg={3} sm={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="orders-input">EASA</Form.Label>
                                                    <Form.Control type="text" id="orders-input" placeholder="EASA" required />
                                                    <div className="invalid-feedback">Please enter EASA standard.</div>
                                                </div>
                                            </Col>

                                            <Col lg={3} sm={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="orders-input">ISO</Form.Label>
                                                    <Form.Control type="text" id="orders-input" placeholder="ISO" required />
                                                    <div className="invalid-feedback">Please enter ISO Standard.</div>
                                                </div>
                                            </Col>

                                        </Row>

                                    </Card.Body>
                                </Card>
                                <div className="text-end mb-3">
                                    <Button variant='success' type="submit" className="w-sm custom-primary-btn">Submit</Button>
                                </div>
                            </Col>


                            <Col xl={3} lg={4}>
                                <Card>
                                    <Card.Header>
                                        <h5 className="card-title mb-0">Publish</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="mb-3">
                                            <label htmlFor="choices-publish-status-input" className="form-label">Status</label>

                                            <select className="form-select" id="choices-publish-status-input" data-choices data-choices-search-false>
                                                <option defaultValue="Published">Published</option>
                                                <option value="Scheduled">Scheduled</option>
                                                <option value="Draft">Draft</option>
                                            </select>
                                        </div>

                                        <div>
                                            <Form.Label htmlFor="choices-publish-visibility-input">Visibility</Form.Label>
                                            <select className="form-select" id="choices-publish-visibility-input" data-choices data-choices-search-false>
                                                <option defaultValue="Public">Public</option>
                                                <option value="Hidden">Hidden</option>
                                            </select>
                                        </div>
                                    </Card.Body>

                                </Card>

                                <Card>
                                    <Card.Header>
                                        <h5 className="card-title mb-0">Publish Schedule</h5>
                                    </Card.Header>

                                    <Card.Body>
                                        <div>
                                            <Form.Label htmlFor="datepicker-publish-input">Publish Date & Time</Form.Label>
                                            <Flatpickr
                                                className="form-control flatpickr-input"
                                                placeholder='Enter publish date'
                                                options={{
                                                    dateFormat: "d M, Y",
                                            }}
                                        />
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default CreateProduct;