import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { fetchAllCustomers, Customer } from '../../Common/data/allCustomers';
import dummyImg from '../../assets/images/users/user-dummy-img.jpg';

const NewCustomers = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customersList = await fetchAllCustomers();

                // Sort customers by most recent date
                const sortedCustomers = customersList.sort((a, b) => {
                    // Assuming the date is in string format, you may need to convert it to a Date object
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateB - dateA;
                });

                // Display only the first 10 customers
                const slicedCustomers = sortedCustomers.slice(0, 10);

                setCustomers(slicedCustomers);
            } catch (error:any) {
                console.error('Error fetching customers:', error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            <div className="col-xxl-3 col-lg-6">
                <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">New Customers</h4>
                        <Link to="/users-list" className="flex-shrink-0">View All <i className="ri-arrow-right-line align-bottom ms-1"></i></Link>
                    </div>

                    <SimpleBar style={{ maxHeight: "445px" }}>
                        {customers.map((customer, key) => (
                            <div className="p-3 border-bottom border-bottom-dashed" key={key}>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="flex-shrink-0">
                                        {/* Assuming there's no productImage property in customer data */}
                                        <img src={dummyImg} alt="" className="rounded dash-avatar" />
                                    </div>
                                    <div className="flex-grow-1">
                                        {/* Display customer full name */}
                                        <h6 className="mb-1">{customer.fullName}</h6>
                                        {/* Display date in the specified format */}
                                        <p className="fs-13 text-muted mb-0">{formatDate(customer.date)}</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <Link to={`mailto:${customer.email}`} className="btn btn-icon btn-sm btn-soft-danger"><i className="ph-envelope"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SimpleBar>
                </div>
            </div>
        </React.Fragment>
    );
}

// Helper function to format date
const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};


export default NewCustomers;
