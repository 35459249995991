import { axiosInstance } from '../../helpers/api_helper';

// Define the admin login function
const adminLogin = async (email: string, password: string) => {
  try {
    const response = await axiosInstance.post('/Admin/login', {
      email,
      password,
    });

    // Log the entire response to inspect its structure
    console.log('Admin login response:', response);
    console.log('Admin login response data:', response.data);

    // You may not need to do anything with the response data if no token or user data is required

    // You can return the response data if needed
    return response.data;
  } catch (error: any) {
    console.error('Admin login failed:', error.message);
    throw error; // You may want to handle this error in the component where you call the adminLogin function
  }
};

export { adminLogin };
