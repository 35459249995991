import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, Form, Row, Spinner } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { updateBannerTwoInfoCard } from '../../../Common/data/updateBannerTwoInfoCard'; // Update import
import { getBannerTwoInfoCard, BannerTwoInfoCard as BannerTwoData } from '../../../Common/data/bannerTwoInfoCard'; // Update import

const BannerTwoInfoCard = () => {
    const [formData, setFormData] = useState({
        updatedBy: 'Admin',
        pageStatus: '2',
        pageID: '811f69d7-0354-491a-9129-5c8d41ac0c3b',
        pageName: '',
        pageContent: '',
    });
    const [successAlert, setSuccessAlert] = useState(false);
    const [bannerTwoData, setBannerTwoData] = useState<BannerTwoData | undefined>(undefined); // Update state name
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setProcessing(true);
        setError('');

        try {
            const updatedData = {
                ...formData,
                pageName: getElementValue('banner-title-input'),
                pageContent: formData.pageContent,
            };

            console.log('Updated BannerTwo Info Data:', updatedData);

            await updateBannerTwoInfoCard(updatedData);

            console.log('BannerTwo Info updated successfully!');
            setSuccessAlert(true);

            // Hide the success alert after 3 seconds
            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        } catch (error: any) {
            console.error('Error updating BannerTwo Info:', error.message);
        } finally {
            setProcessing(false);
        }
    };

    const getElementValue = (elementId: string): string => {
        const element = document.getElementById(elementId);

        if (element && 'value' in element) {
            return element.value as string;
        }

        return '';
    };

    const fetchBannerTwoDetails = async () => {
        try {
            const data = await getBannerTwoInfoCard();
            setBannerTwoData(data);
        } catch (error: any) {
            console.error('Error fetching Banner Two Info Card details:', error.message);
        }
    };

    useEffect(() => {
        // Fetch banner two details when the component mounts
        fetchBannerTwoDetails();
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <Card>
            <Card.Header>
                <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                            <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                <i className="bi bi-box-seam"></i>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Banner Two Information</h5> {/* Update title */}
                        <p className="text-muted mb-0">Fill in all information below.</p>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Form.Label htmlFor="banner-title-input">Title</Form.Label>
                            <Form.Control
                                type="text"
                                id="banner-title-input"
                                defaultValue={bannerTwoData?.pageName}
                                placeholder="Enter a title"
                                required
                                onChange={(e) => setFormData({ ...formData, pageName: e.target.value })}
                            />
                            <div className="invalid-feedback">Please enter a title.</div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Form.Label htmlFor="banner-content-input">Content</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={bannerTwoData?.pageContent || ''}
                                onReady={(editor: any) => {
                                    // You can store the "editor" and use it when needed.
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, editor: any) => {
                                    if (editor) {
                                        const content: string = editor.getData();
                                        setFormData({ ...formData, pageContent: content });
                                    }
                                }}
                            />
                            <div className="invalid-feedback">Please enter content.</div>
                        </div>
                    </Col>
                </Row>

                {error && <Alert variant="danger">{error}</Alert>}
                {successAlert && (
                    <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                        <p>BannerTwo Info updated successfully!</p> {/* Update success message */}
                    </Alert>
                )}
                <Button type="submit" variant="primary" onClick={handleSubmit}>
                    {processing ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                </Button>
            </Card.Body>
        </Card>
    );
};

export default BannerTwoInfoCard;
