import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Alert, Spinner } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import { fetchProfileDetails } from "../../../Common/data/profile";
import { updateAdmin } from "../../../Common/data/updateadmin";
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const PersonalInformation = () => {

    const [profile, setProfile] = useState<any>(null);
    const [alert, setAlert] = useState({
        show: false,
        variant: 'success', // 'success', 'danger', 'warning', etc.
        message: '',
    });
    const [processing, setProcessing] = useState(false); // State to track whether update process is ongoing

    const authUser = localStorage.getItem('authUser');
    const { data: { profileID, email } } = authUser ? JSON.parse(authUser) : { data: { profileID: '', email: '' } };

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await fetchProfileDetails();
                setProfile(profileData);
            } catch (error: any) {
                console.error('Error fetching profile details:', error.message);
            }
        };

        fetchProfile();
    }, []);

    console.log("Profile ID:", profileID);
    console.log("Email:", email);

    const initialValues = {
        userName: profile?.name || '',
        phoneInput: profile?.phone || '',
    };

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('User Name is required'),
        phoneInput: Yup.string(),
    });

    const handleSubmit = async (values: any, actions: any) => {
        try {
            const updatedData = {
                name: values.userName,
                phone: values.phoneInput,
                email: email || '',
                role: "1",
                profileID: profileID || '',
            };

            console.log("updated Data:", updatedData);

            // Set processing to true to show spinner in button
            setProcessing(true);

            // Call the updateVendor function
            await updateAdmin(updatedData);

            console.log('Profile updated successfully!');

            // Show success alert
            setAlert({
                show: true,
                variant: 'success',
                message: 'Profile updated successfully!',
            });
        } catch (error: any) {
            console.error('Error updating profile:', error.message);

            // Show error alert
            setAlert({
                show: true,
                variant: 'danger',
                message: 'Error updating profile. Please try again.',
            });
        } finally {
            // Set processing to false to hide spinner in button
            setProcessing(false);
        }
    };

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            {/* Alert */}
            {alert.show && (
                <Alert variant={alert.variant} onClose={() => setAlert({ show: false, variant: 'success', message: '' })} dismissible>
                    {alert.message}
                </Alert>
            )}

            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Personal Information</h5>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <FormikForm>
                                        <Row className="g-3">
                                            <Col lg={4}>
                                                <div>
                                                    <Form.Label htmlFor="userName">User Name <span className="text-danger">*</span></Form.Label>
                                                    <Field type="text" id="userName" name="userName" placeholder="Enter your user name" className="form-control" />
                                                    <ErrorMessage name="userName" component="div" className="text-danger" />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <Form.Label htmlFor="phoneInput">Phone Number</Form.Label>
                                                    <Field type="text" id="phoneInput" name="phoneInput" placeholder="Enter phone number" className="form-control" />
                                                    <ErrorMessage name="phoneInput" component="div" className="text-danger" />
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="text-end">
                                                    <Button
                                                        variant='secondary'
                                                        type="submit"
                                                        className="custom-primary-btn"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Update Profile'}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormikForm>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PersonalInformation;
