import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, Row, Spinner } from 'react-bootstrap';
import { updateStoreDetails } from '../../../Common/data/updateStoreDetails';
import { getStoreDetails, StoreDetails } from 'Common/data/storedetails';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const TopBarInfo = () => {
    const [successAlert, setSuccessAlert] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [storeDetails, setStoreDetails] = useState<StoreDetails | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStoreDetails = async () => {
            try {
                const details: StoreDetails[] | undefined = await getStoreDetails();
                const storeDetails = details && details.length > 0 ? details[0] : undefined;
                if (storeDetails) {
                    setStoreDetails(storeDetails);
                }
            } catch (error: any) {
                console.error('Error fetching store details:', error.message);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchStoreDetails();
    }, []);

    const handleSubmit = async (values: any) => {
        setProcessing(true);

        try {
            const updatedData = {
                ...values,
            };

            await updateStoreDetails(updatedData);

            console.log('Store details updated successfully!');
            setSuccessAlert(true);

            // Hide the success alert after 3 seconds
            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        } catch (error: any) {
            console.error('Error updating store details:', error.message);
        } finally {
            setProcessing(false);
        }
    };

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <Card>
            <Card.Header>
                <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                            <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                <i className="bi bi-box-seam"></i>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Top Bar Information</h5>
                        <p className="text-muted mb-0">Fill all information below.</p>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Formik
                    initialValues={{
                        shopName: "Aeropartx Nigeria Ltd",
                        shopAddress: "Somewhere in Lagos",
                        shopCity: "Lagos",
                        shopState: "Lagos",
                        shopDesc: "Your shop one-stop-shop for all aeroplanes part",
                        shopStatus: "1",
                        shopID: "b2014334-c8bb-40ca-89f1-ca779b1d2543",
                        shopEmail: storeDetails?.shopEmail || '',
                        shopPhone: storeDetails?.shopPhone || '',
                    }}
                    validationSchema={Yup.object({
                        shopEmail: Yup.string()
                            .email('Invalid email address')
                            .required('Required'),
                        shopPhone: Yup.string()
                            .matches(/^[0-9]+$/, 'Must be only digits')
                            .required('Required'),
                    })}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ isSubmitting, errors, touched, values }) => ( 
                        <Form>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label htmlFor="shopEmail">Company Email</label>
                                        <Field
                                            type="text"
                                            id="shopEmail"
                                            name="shopEmail"
                                            className={`form-control ${errors.shopEmail && touched.shopEmail ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.shopEmail}</div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label htmlFor="shopPhone">Company Phone No</label>
                                        <Field
                                            type="text"
                                            id="shopPhone"
                                            name="shopPhone"
                                            className={`form-control ${errors.shopPhone && touched.shopPhone ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.shopPhone}</div>
                                    </div>
                                </Col>
                            </Row>
                            {/* Success alert */}
                            {successAlert && (
                                <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                                    <p>Store details updated successfully!</p>
                                </Alert>
                            )}
                            {/* Save button */}
                            <Button 
                                type="button" 
                                variant="primary" 
                                disabled={isSubmitting || Object.keys(errors).length > 0}
                                onClick={() => handleSubmit(values)}
                            >
                                {processing ? (
                                    <Spinner animation="border" size="sm" className="me-2" />
                                ) : null}
                                Save Changes
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default TopBarInfo;
