import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Alert, Form, Row, Spinner } from 'react-bootstrap';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updatePurchaseGuide } from '../../../Common/data/updatePurchaseGuide';
import { getPurchaseGuide, PurchaseGuide as GuideData } from '../../../Common/data/purchaseGuide';

type EditorType = {
    getData: () => string;
};

const PurchaseGuide: React.FC = () => {
    const [formData, setFormData] = useState({
        updatedBy: 'Admin',
        pageStatus: '2',
        pageID: 'b1f81418-439f-45df-bb7a-b8deab4c175a',
        pageName: "Purchase Guide",
        pageContent: '',
    });
    const [successAlert, setSuccessAlert] = useState(false);
    const [guideData, setGuideData] = useState<GuideData | undefined>(undefined);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setProcessing(true);

        try {
            const updatedData = {
                ...formData,
                pageContent: formData.pageContent,
            };

            console.log('updated Data:', updatedData);

            await updatePurchaseGuide(updatedData);

            console.log('Purchase Guide details updated successfully!');
            setSuccessAlert(true);

            // Hide the success alert after 3 seconds
            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        } catch (error: any) {
            console.error('Error updating purchase guide details:', error.message);
        } finally {
            setProcessing(false);
        }
    };

    const fetchGuideDetails = async () => {
        try {
            const data = await getPurchaseGuide();
            setGuideData(data);
        } catch (error: any) {
            console.error('Error fetching purchase guide details:', error.message);
        }
    };

    useEffect(() => {
        // Fetch purchase guide details when the component mounts
        fetchGuideDetails();
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <Card>
            <Card.Header>
                <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                            <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                <i className="bi bi-box-seam"></i>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Purchase Guide</h5>
                        <p className="text-muted mb-0">Fill all information below.</p>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={12}>
                        <div className="mb-3">
                            <Form.Label htmlFor="purchase-guide-input-description">Content</Form.Label>

                            {/* Replace Form.Control with CKEditor */}
                            <CKEditor
                                editor={ClassicEditor}
                                data={guideData?.pageContent || ''}
                                onReady={(editor: any) => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, editor: any) => {
                                    if (editor) {
                                        const content: string = editor.getData();
                                        setFormData({ ...formData, pageContent: content });
                                    }
                                }}
                            />

                            <div className="invalid-feedback">Please enter a description.</div>
                        </div>
                    </Col>
                </Row>

                {successAlert && (
                    <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
                        <p>Purchase Guide details updated successfully!</p>
                    </Alert>
                )}
                <Button type="submit" variant="primary" onClick={handleSubmit}>
                    {processing ? <Spinner animation="border" size="sm" className="me-2" /> : null}
                    Save Changes
                </Button>
            </Card.Body>
        </Card>
    );
};

export default PurchaseGuide;
