import { axiosInstance } from '../../helpers/api_helper';

interface Seller {
  accountStatus: number;
  vendorName: string;
  vendorEmail: string;
  vendorPhone: string;
  vendorAddress: string;
  vendorID: string;
  vendorRCNo: string;
  city: string;
  status: string;
}

async function fetchAllSellers(): Promise<Seller[]> {
  const apiUrl = `/Vendor`;

  try {
    const response = await axiosInstance.get(apiUrl);

    // Log the entire response
    console.log('API Response:', response);
    console.log('API Response Data:', response.data);

    const sellersList = response.data;

    console.log("Sellers List", sellersList);
    return sellersList;
  } catch (error: any) {
    console.error('Error:', error.message);
    // Handle errors here
    throw error;
  }
}

export { fetchAllSellers };    export type { Seller };

