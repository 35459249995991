import { axiosInstance } from '../../helpers/api_helper';

const updatePurchaseGuide = async (formData: any) => {
  try {
    console.log('FormData in updatePurchaseGuide:', formData);

    const response = await axiosInstance.patch(`/FrontPage/UpdatePage`, formData);

    console.log('API Response from updatePurchaseGuide:', response); // Log the response

    if (response.data.status === "00") {
      // Handle success, if needed
      return response.data;
    } else {
      // Handle error
      throw new Error('Error updating purchase guide details');
    }
  } catch (error:any) {
    // Handle network or other errors
    throw new Error(`Error: ${error.message}`);
  }
};

export { updatePurchaseGuide };
